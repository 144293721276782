<template>
  <div class="px-5">
    <a-form layout="inline">
      <a-form-item label="项目">
        <a-select v-model:value="searchModel.factoryId" style="width: 200px;" :dropdownMatchSelectWidth="false"
                  @change="selectFactorySearch"
                  allowClear placeholder="请选择项目">
          <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
              item.factoryName
            }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="区域">
        <a-select v-model:value="searchModel.groupId" style="width: 200px;" :dropdownMatchSelectWidth="false"
                  @change="selectGroupSearch"
                  allowClear placeholder="请选择">
          <a-select-option v-for="(item, index) in groupSearchList" :key="index" :value="item.id">{{
              item.name
            }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="设备">
        <a-select v-model:value="searchModel.machineId" style="width: 200px;" :dropdownMatchSelectWidth="false"
                  allowClear placeholder="请选择设备">
          <a-select-option v-for="(item, index) in machineSearchList" :key="index" :value="item.id">{{
              item.machineName
            }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="发生时间">
        <a-range-picker v-model:value="searchModel.date" style="width: 400px" show-time format="YYYY/MM/DD HH:mm:ss"
                        allowClear :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
      </a-form-item>

      <a-form-item label="严重程度">
        <a-select allowClear v-model:value="searchModel.condition" style="width: 160px">
          <a-select-option v-for="(item, index) in currentConditionList"
                           :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="诊断经理">
        <a-select allowClear :showSearch="true" v-model:value="searchModel.diagnosticManager" style="width: 180px">
          <a-select-option v-for="(item, index) in diagnosticManagerList" :key="index" :value="item._id">
            {{ item.nickName }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="search()">查询</a-button>
      </a-form-item>
    </a-form>

    <div class="w-full flex items-center mt-4">
      <div style="margin-left: 50px; font-size: 16px; padding: 0 10px 5px 10px; cursor: pointer"
           :class="{ 'select-tag': selectTag == 0 }" @click="tagSelect(0)">
        待审核
      </div>
      <div style="margin-left: 50px; font-size: 16px; padding: 0 10px 5px 10px; cursor: pointer"
           :class="{ 'select-tag': selectTag == 1 }" @click="tagSelect(1)">
        已审核
      </div>


      <div class="flex-1"></div>
      <div class="flex items-center event-btns">
        <a-button @click="sure" :disabled="isCommit">确认提交</a-button>
        <a-button @click="cancelClick" class="ml-2">取消选择</a-button>
      </div>

    </div>

    <div class="main">
      <a-table bordered :emptyText="'暂无数据'" :columns="columns" :data-source="dataList" :pagination="pagination"
               :loading="loading" @change="handleTableChange" size="small" :row-class-name="getRowClassName">
        <template #bodyCell="{ column, text, record, index }">
          <template v-if="column.dataIndex === 'factoryName'"> {{
              factoryAll.find(p => p._id ===
                  record.factoryId)?.factoryName
            }}
          </template>

          <template v-if="column.dataIndex === 'condition'">
            {{ conditionList.find(p => p.value === text)?.label }}
          </template>

          <template v-if="column.dataIndex === 'expertDiagnosisResult'">
            <a-select v-if="record.status !== 3" v-model:value="record.expertDiagnosisResult" style="width: 100%"
                      :options="conditionList" @change="verifySure()"></a-select>

            <span v-if="record.status === 3">{{
                conditionList.find(p => p.value ===
                    record.expertDiagnosisResult)?.label
              }}</span>
          </template>

          <template v-if="column.dataIndex === 'status'">
            {{ statusList.find(p => p.value === record.status)?.label }}
            <!-- <a-select v-model:value="record.status" style="width: 100%"
              :options="statusList" @change="verifySure(index, record)"></a-select> -->
          </template>

          <template v-if="column.dataIndex === 'diagnosticName'">
            <div>{{ getDiagnosticName(record.machineId) }}</div>
          </template>

          <template v-if="column.dataIndex === 'select'">
            <input v-if="!record.cancel && record.status !== 3" type="checkbox"
                   style="width: 16px; height: 16px; cursor: pointer" v-model="record.isClick"
                   @change="verifySelect(record)"/>
          </template>

          <template v-if="column.dataIndex === 'act'">
            <a @click="viewDetail(record)">查看</a>
            <a-divider type="vertical"/>
            <a-popconfirm
                title="确认删除"
                @confirm="deleteOrder(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>

        </template>
      </a-table>
    </div>


    <!-- 诊断去处理 -->
    <a-modal :width="1300" v-model:visible="visibleEdit" title="诊断处理" :footer="null" :maskClosable="false">
      <a-form style="margin-top: 20px" :model="editForm" name="basic" :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }">
        <a-form-item label="诊断判断内部备注" name="internalNotes" :rules="[{ required: true, message: '请填写' }]">
          <a-select
              v-model:value="editForm.internalNotes"
              style="width: 100%"
              :options="internalNotesList.map(item => ({ value: item }))"
          >
            <template #dropdownRender="{ menuNode: menu }">
              <v-nodes :vnodes="menu"/>
              <a-divider style="margin: 4px 0"/>
              <div
                  style="padding: 4px 8px; cursor: pointer"
                  @mousedown="e => e.preventDefault()"
                  @click="addinternalNote()"
              >
                <div class="flex items-center" style="color: #000">
                  <plus-outlined/>
                  添加
                </div>
              </div>
            </template>
          </a-select>
        </a-form-item>

        <a-form-item label="诊断判断备注" name="diagnosticNotes"
                     :rules="[{ required: true, message: '请填写诊断判断备注'}]">
          <a-textarea v-model:value="editForm.diagnosticNotes" placeholder="" :rows="4"/>
        </a-form-item>

        <a-form-item label="是否前台显示" name="display">
          <a-radio-group v-model:value="editForm.display">
            <a-radio :value="true">是</a-radio>
            <a-radio :value="false">否</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 4, span: 20 }">
          <a-button @click="editSubmit" :loading="subLoading">保存任务处理</a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 查看 -->
    <a-modal :width="1300" v-model:visible="visibleView" title="查看" :footer="null" :maskClosable="false">
      <a-table ref="viewTable" :columns="viewColumns" :data-source="viewForm.list" :pagination="false">
        <template #bodyCell="{ column, record, text }">
          <template v-if="column.dataIndex === 'factoryId'">
            <span>{{ factoryAll.find(p => p._id === record.factoryId)?.factoryName }}</span>
          </template>

          <template v-if="column.dataIndex === 'condition'">
            <span>{{ conditionList.find(p => p.value === text)?.label }}</span>
          </template>

          <template v-if="column.dataIndex === 'expertDiagnosisResult'">
            <span>{{ conditionList.find(p => p.value === text)?.label }}</span>
          </template>

          <template v-if="column.dataIndex === 'display'">
            <span>{{ switchList.find(p => p.value === text)?.label }}</span>
          </template>

          <template v-if="column.dataIndex === 'status'">
            <span>{{ statusList.find(p => p.value === text)?.label }}</span>
          </template>

        </template>
      </a-table>

      <a-form style="margin-top: 20px" :model="viewForm" name="basic" :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }">
        <a-form-item label="诊断判断内部备注" name="internalNotes">
          <a-textarea readonly v-model:value="viewForm.internalNotes" placeholder=" " :rows="4"/>
        </a-form-item>

        <a-form-item label="诊断判断备注" name="diagnosticNotes">
          <a-textarea readonly v-model:value="viewForm.diagnosticNotes" placeholder=" " :rows="4"/>
        </a-form-item>
      </a-form>

    </a-modal>

    <!-- 新增诊断判断内部备注 -->
    <a-modal :width="400" :maskClosable="false" v-model:visible="addIVisible" title="新增诊断判断内部备注"
             @ok="submitAddInternalNote">
      <a-input style="width: 350px" v-model:value="addIString"/>
    </a-modal>

  </div>
</template>

<script lang="ts" setup>
import request from "../../../common/request";
import { transformDate, getGroupListByFacID, getGroupName } from "../../../common/tools";
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import { message, TableProps } from "ant-design-vue";
import { v4 as uuidv4 } from "uuid";
import addReportComponent from "../../report4/diagnosticReport-add/diagnosticReport-add.vue";
import { Dayjs } from 'dayjs';

let factoryAll: any = ref([])
let factoryList: any = ref([])
let groupSearchList: any = ref([])
let machineSearchList: any = ref([])
let diagnosticManagerList: any = ref([])

let searchModel: any = ref({
  skip: 1,
  take: 20,
  total: 0
})
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
  showSizeChanger: true,
}));
let dataList: any = ref([])


let selectTag = ref(0)  //0: 待审核 1: 已审核

let loading: any = ref(false)
let bindMachineList: any = ref([])

const columns = [
  {
    title: "项目",
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: "区域",
    dataIndex: "groupName",
    align: "center"
  },
  {
    title: "设备",
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: "测点",
    dataIndex: "sensorPlace",
    align: "center"
  },
  {
    title: "严重程度",
    dataIndex: "condition",
    align: "center"
  },
  {
    title: "报警时间",
    dataIndex: "date",
    align: "center"
  },
  {
    title: "报警内容",
    dataIndex: "diagnosticNotes",
    align: "center"
  },
  {
    title: "严重程度",
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },
  {
    title: "诊断经理",
    dataIndex: "diagnosticName",
    align: "center"
  },
  {
    title: "审核状态",
    dataIndex: "status",
    align: "center"
  },
  {
    title: "项目",
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: "选择",
    dataIndex: "select",
    align: "center"
  },
  {
    title: "操作",
    dataIndex: "act",
    align: "center"
  },
]

let conditionList = [
  { label: '健康', value: 0 },
  { label: '可用上', value: 1 },
  { label: '可用下', value: 2 },
  { label: '警戒上', value: 3 },
  { label: '警戒中', value: 4 },
  { label: '警戒下', value: 5 },
  { label: '故障上', value: 6 },
  { label: '故障下', value: 7 },
  { label: ' ', value: -1 },
]

let statusList = [
  { label: '未审核', value: 0 },
  { label: '已审核', value: 3 },
]

let currentConditionList = [
  { label: "可用", value: 1, realValue: [1, 2] },
  { label: "警戒及以下", value: 2, realValue: [3, 4, 5, 6, 7] },
  { label: "不限", value: null, realValue: null },
]

let isCommit: any = ref(true)
let visibleEdit: any = ref(false)
let subLoading: any = ref(false)
let editForm: any = ref({})

let internalNotesList: any = ref([
  '开关机异常',
  '偶然冲击',
  '工况干扰',
  '数据清洗',
  '阈值问题',
  '通用指标不合理',
  '通用指标有问题',
  '专业指标不合理',
  '转频提取错误',
  '特征提取错误',
  '信号处理',
  '硬件采集问题',
  '硬件位置异常',
  '程序错误',
])

const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes;
  },
});

let addIString: any = ref('')
let addIVisible = ref(false)  //新增诊断判断内部备注

let viewForm: any = ref({})
let visibleView: any = ref(false)
const viewColumns = [
  {
    title: "项目",
    dataIndex: "factoryId",
    align: "center"
  },
  {
    title: "区域",
    dataIndex: "groupName",
    align: "center"
  },
  {
    title: "设备",
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: "测点",
    dataIndex: "sensorPlace",
    align: "center"
  },
  {
    title: "严重程度",
    dataIndex: "condition",
    align: "center"
  },
  {
    title: "严重程度",
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },
  {
    title: "审核状态",
    dataIndex: "status",
    align: "center"
  },
  {
    title: "是否前台显示",
    dataIndex: "display",
    align: "center"
  },

]

let switchList = [
  { label: '是', value: true },
  { label: '否', value: false },
]

const dates = ref<any>();
const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return record.indexMD % 2 === 0 ? "odd-row" : "even-row";
};

onMounted(() => {
  init()
})

const init = () => {
  getBindMachineList()
  getManagers()
  getFactoryList()
  search()
}

// 获取绑定的设备
const getBindMachineList = async () => {
  let result = await request.get("/api/principal/all")

  bindMachineList.value = result?.data || []

}

// 获取诊断/客户经理列表
const getManagers = async () => {
  const config = {
    params: {
      factoryId: "65e29561f747d7e32bbed049",
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let resultRole = await request.get("/api/roles", config)
  let rolelist: any = []
  if (resultRole?.data?.list) {
    rolelist = resultRole.data.list
  }
  let diagnosticManagerRoleId = rolelist.find((p: any) => p.name === '诊断工程师')?.id

  if (diagnosticManagerRoleId) {
    let config2 = {
      params: {
        roleId: diagnosticManagerRoleId,
        factoryId: "65e29561f747d7e32bbed049",
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result2 = await request.get("/api/members", config2)
    if (result2?.data?.list) {
      diagnosticManagerList.value = result2.data.list
    }
  }
}

// 获取工厂列表
const getFactoryList = async () => {
  let result = await request.get('/api/factories')
  if (result && result.data) {
    factoryAll.value = result.data
    let parentIds = result.data.map((p: any) => p.parentId)
    parentIds = [...new Set(parentIds)]
    let list = result.data.filter((p: any) => !parentIds.includes(p._id))
    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    list = list.filter((p: any) => memberInfo.factoryIds.includes(p._id))
    factoryList.value = list
  }
}

// 获取查询设备列表
const selectFactorySearch = async (ev: any) => {
  if (ev) {
    groupSearchList.value = getGroupListByFacID(ev)
  } else {
    groupSearchList.value = []
  }
  searchModel.value.groupId = null
  searchModel.value.machineId = null
}

// 选择查询区域
const selectGroupSearch = async (ev: any) => {
  if (ev) {
    const config = {
      params: {
        factoryId: searchModel.value.factoryId,
        groupId: searchModel.value.groupId,
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.get('/api/machines', config)
    if (result && result.data) {
      machineSearchList.value = result.data
    } else {
      machineSearchList.value = []
    }
  } else {
    machineSearchList.value = []
  }
}

const onCalendarChange = (val: any) => {
  dates.value = val;
};


const search = () => {
  searchModel.value.skip = 1
  getData()
}

// 获取列表数据
const getData = async () => {
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    searchModel.value.begin = transformDate(searchModel.value.date[0])
    searchModel.value.end = transformDate(searchModel.value.date[1])
  } else {
    searchModel.value.begin = ''
    searchModel.value.end = ''
  }

  const config = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      factoryId: searchModel.value.factoryId,
      machineId: searchModel.value.machineId,
      begin: searchModel.value.begin,
      end: searchModel.value.end,
      sorter: searchModel.value.sorter,
      diagnosticManager: searchModel.value.diagnosticManager,
      type: 4,
      status: selectTag.value === 0 ? 0 : 3,
    } as any,
    headers: {
      requestId: uuidv4(),
    },
  };

  if (searchModel.value.condition) {
    config.params.conditionList = currentConditionList.find((p: any) => p.value === searchModel.value.condition)?.realValue || []
  }

  let result = await request.get('/api/workflows/info', config)

  if (result && result.data) {
    dataList.value = result.data.list
    let indexMD = 0
    let dataMD = ''
    dataList.value.map((d: any) => {
      d.groupName = getGroupName(d.groupId)
      let md = d.machineId + d.date
      if (dataMD === md) {
        d.indexMD = indexMD
      } else {
        indexMD++
        d.indexMD = indexMD
        dataMD = md
      }
    })
    // searchModel.value.skip = 1
    searchModel.value.total = result.data.total
  }


}

const handleTableChange = (ev: any, a: any, sorter: any) => {
  searchModel.value = {
    ...searchModel.value,
    skip: ev.current,
    take: ev.pageSize,
  }
  if (sorter.order && sorter.field) {
    searchModel.value.sorter = sorter.order === 'descend' ? false : true
  }
  getData()
}


const tagSelect = (ev: any) => {
  searchModel.value.skip = 1
  selectTag.value = ev
  search()
}


// 选择验证
const verifySelect = (ev: any) => {
  if (ev.isClick) {
    let list = dataList.value.filter((p: any) => p.isClick)
    let machineIds = [...new Set(list.map((p: any) => p.machineId))]
    if (machineIds?.length > 1) {
      isCommit.value = true
      message.warning('请选择相同设备的数据！')
      return
    }
    if (!list?.length) {
      isCommit.value = true
      return
    }

    list.map((d: any) => {
      let list1 = dataList.value.filter((p: any) => p.date === d.date && p.machineId === d.machineId)
      list1.map((d: any) => { d.isClick = true })
    })
  }

  let list2 = dataList.value.filter((p: any) => p.isClick)

  for (let item of list2) {
    if ((!item.expertDiagnosisResult && item.expertDiagnosisResult !== 0) || item.expertDiagnosisResult < 0) {
      isCommit.value = true
      message.warning('请选择严重程度！')
      return
    }
  }
  isCommit.value = false
}

// 选择严重程度
const verifySure = () => {
  let list = dataList.value.filter((p: any) => p.isClick)

  for (let item of list) {
    if ((!item.expertDiagnosisResult && item.expertDiagnosisResult !== 0) || item.expertDiagnosisResult < 0) {
      isCommit.value = true
      message.warning('请选择严重程度！')
      return
    }
  }
  isCommit.value = false
}

// 确认提交
const sure = () => {
  let list = dataList.value.filter((p: any) => p.isClick)
  let diagnosticNotes: any = []
  list.map((d: any) => {
    if (d.diagnosticNotes) {
      diagnosticNotes.push(d.diagnosticNotes)
    }
  })
  editForm.value.diagnosticNotes = diagnosticNotes.join(';')
  editForm.value.display = false
  visibleEdit.value = true
}

// 添加诊断判断内部备注
const addinternalNote = () => {
  addIString.value = ''
  addIVisible.value = true
}

const submitAddInternalNote = () => {
  internalNotesList.value.push(addIString.value)
  editForm.value.internalNotes = addIString.value
  addIVisible.value = false
}

// 保存任务处理
const editSubmit = async () => {
  subLoading.value = true
  let list = JSON.parse(JSON.stringify(dataList.value.filter((p: any) => p.isClick)))
  list.map((d: any) => {
    delete d.isClick
    d.status = 3
  })

  let submitForm = {
    display: editForm.value.display,
    diagnosticNotes: editForm.value.diagnosticNotes,
    internalNotes: editForm.value.internalNotes,
    workflowInfos: list,
  }

  let result = await request.put('/api/workflows/threshold/info', submitForm)
  subLoading.value = false

  if (result && result.status === 200) {
    subLoading.value = false
    message.success('操作成功')
    visibleEdit.value = false
    search()
  } else {
    subLoading.value = false
    message.warning('操作失败, 请联系管理员!')
  }

}


// 取消选择
const cancelClick = () => {
  dataList.value.map((d: any) => { d.isClick = false })
  isCommit.value = true
}

// 删除
const deleteOrder = async (ev: any) => {
  let config = {
    params: { id: ev.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.delete('/api/workflows/info', config)
  if (result && result.data) {
    setTimeout(() => {
      search()
      message.success('删除成功')
    }, 1000);
  } else {
    message.success('删除失败')
  }
}

// 查看
const viewDetail = async (ev: any) => {
  viewForm.value = ref({
    list: [{
      factoryId: '',
      machineName: '',
      sensorPlace: '',
      scoreName: '',
      date: null,
      condition: null,
      expertDiagnosisResult: null,
      expertProcess: null,
      display: null,
    }],
    diagnosticNotes: '',
    internalNotes: '',
    reportId: '',
  })
  visibleView.value = true

  const config = {
    params: {
      id: ev.id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get('/api/workflows/infos', config)
  if (result && result.data) {
    let info = result.data
    viewForm.value = {
      list: [{
        factoryId: ev.factoryId,
        machineName: info.machineName,
        sensorPlace: info.sensorPlace,
        scoreName: info.scoreName,
        date: info.date,
        condition: info.condition,
        expertDiagnosisResult: info.expertDiagnosisResult,
        expertProcess: info.expertProcess,
        display: info.display,
        groupName: ev.groupName,
        status: ev.status
      }],
      diagnosticNotes: info.diagnosticNotes,
      internalNotes: info.internalNotes,
    }
  }

}

const getDiagnosticName = (ev: any) => {
  let name = ''
  let bmInfo = bindMachineList.value.find((p: any) => p.machineId === ev)
  if (bmInfo) {
    let user = diagnosticManagerList.value.find((p: any) => p._id === bmInfo.diagnosticManager)
    name = user?.nickName || ''
  }
  return name
}

</script>

<style scoped lang="less">

.code-box {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 0px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 15px;
}

.main {
  margin-top: 30px;
}

.select-tag {
  border-bottom: 1px solid #1890ff;
  color: #1890ff;
}


.event-btns {
  /deep/ .ant-btn {
    background-color: rgb(7, 36, 153);
    border: 1px solid #0d53b7cc;;
    color: #fff;
  }
}

/deep/ .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: #020f46 !important;
}


</style>
