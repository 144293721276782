<template>
  <div class="box">
    <div class="w-full flex px-4" style="border-bottom: 1px solid #4a5983">
      <div
          style="font-size: 18px"
          class="py-3 cursor-pointer"
          @click="selectMenu(1)"
          :style="{ borderBottom: menuSelect === 1 ? '2px solid #00FFF4' : '' }"
      >
        {{ langObj['设备列表'] }}
      </div>

      <div
          style="font-size: 18px"
          class="py-3 ml-12 cursor-pointer"
          @click="selectMenu(2)"
          :style="{ borderBottom: menuSelect === 2 ? '2px solid #00FFF4' : '' }"
      >
        {{ langObj['测点列表'] }}
      </div>
    </div>

    <div class="p-3 w-full">
      <div v-if="menuSelect === 1">
        <a-form
            :model="searchModel"
            name="horizontal_login"
            layout="inline"
            autocomplete="off"
        >
          <a-form-item :label="langObj['设备区域']" name="groupId" allowClear>
            <a-select
                v-model:value="searchModel.groupId"
                style="width: 220px"
                allowClear
                :placeholder="langObj['请选择区域']"
            >
              <a-select-option
                  v-for="(item, index) in groupList"
                  :key="index"
                  :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="langObj['健康状态']" name="condition">
            <a-select
                v-model:value="searchModel.condition"
                style="width: 220px"
                allowClear
                :placeholder="langObj['请选择健康状态']"
            >
              <a-select-option
                  v-for="(item, index) in conditionLists"
                  :key="index"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="langObj['运行状态']" name="status">
            <a-select
                v-model:value="searchModel.status"
                style="width: 220px"
                allowClear
                :placeholder="langObj['请选择运行状态']"
            >
              <a-select-option
                  v-for="(item, index) in statusList"
                  :key="index"
                  :value="item.value"
              >
                {{ langObj[item.label] }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="langObj['搜索']" name="status">
            <a-input
                v-model:value="searchModel.keyWord"
                :placeholder="langObj['请输入设备名称/编号']"
            />
          </a-form-item>

          <a-form-item>
            <div class="flex items-center">
              <div @click="reset" class="box px-5 py-1 fff cursor-pointer ml-4">
                {{ langObj['重置'] }}
              </div>

              <div
                  @click="getData"
                  class="box px-5 py-1 fff cursor-pointer ml-4"
                  style="background-color: #072499"
              >
                {{ langObj['查询'] }}
              </div>

              <div
                  v-if="isEditable"
                  @click="addMachine"
                  class="box px-5 py-1 fff cursor-pointer ml-4"
                  style="background-color: #072499"
              >
                {{ langObj['新建设备'] }}
              </div>

              <div
                  v-if="isEditable"
                  @click="visibleImportMachine=true"
                  class="box px-5 py-1 fff cursor-pointer ml-4"
                  style="background-color: #072499">
                {{ langObj['导入设备'] || '导入设备' }}
              </div>

            </div>
          </a-form-item>
        </a-form>

        <div class="box mt-4" style="width: 100%">
          <a-table
              style="width: 100%"
              size="small"
              :row-class-name="getRowClassName"
              :columns="machineColumns"
              :data-source="data"
              :pagination="false"
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'machineTypeAll'">
                <span>{{ getLanguage(text, language) }}</span>
              </template>

              <template v-if="column.dataIndex === 'mode'">
                <span>{{ getMode(record.mode) }}</span>
              </template>

              <template v-if="column.dataIndex === 'factoryId'">
                <span>{{ getFactoryName(text) }}</span>
              </template>

              <template v-if="column.dataIndex === 'groupId'">
                <span>{{ getGroupName(text) }}</span>
              </template>

              <template v-if="column.dataIndex === 'experiment'">
                <span>{{
                    whetherList.find((p) => p.value === text)?.label
                  }}</span>
              </template>

              <template v-if="column.dataIndex === 'condition'">
                <div class="flex items-center">
                  <div
                      style="width: 14px; height: 14px; border-radius: 50%"
                      :style="{ background: getMachienCondition(record)?.color }"
                  ></div>
                  <div
                      class="ml-1"
                      :style="{ color: getMachienCondition(record)?.color }"
                  >
                    {{ langObj[getMachienCondition(record)?.label] }}
                  </div>
                </div>
              </template>

              <template v-if="column.dataIndex === 'status'">
                <div
                    class="ml-1"
                    :style="{ color: getMachienStatus(record)?.color }"
                >
                  {{ langObj[getMachienStatus(record)?.label] }}
                </div>
              </template>

              <template v-if="column.dataIndex === 'act'">
                <a
                    v-if="isEditable"
                    @click="editMachine(record)"
                    style="color: #00fff4 !important"
                > {{ langObj['编辑'] }}</a
                >
                <a-divider type="vertical"/>
                <a
                    @click="openMachineWarining(record)"
                    style="color: #00fff4 !important"
                > {{ langObj['报警记录'] }}</a
                >
                <a-divider type="vertical"/>
                <a
                    @click="viewMachine(record)"
                    style="color: #00fff4 !important"
                > {{ langObj['健康总览'] }}</a
                >
              </template>
            </template>
          </a-table>
        </div>
      </div>

      <div v-if="menuSelect === 2">
        <a-form
            :model="searchModel"
            name="horizontal_login"
            layout="inline"
            autocomplete="off"
        >
          <a-form-item :label="langObj['设备区域']" name="groupId">
            <a-select
                v-model:value="searchModel.groupId"
                style="width: 220px"
                @change="selectGroup"
            >
              <a-select-option
                  v-for="(item, index) in groupList"
                  :key="index"
                  :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="langObj['设备']" name="machineId">
            <a-select
                v-model:value="searchModel.machineId"
                style="width: 220px"
            >
              <a-select-option
                  v-for="(item, index) in machineList"
                  :key="index"
                  :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item
          >
          、

          <a-form-item :label="langObj['测点位置']" name="status">
            <a-input
                :placeholder="langObj['请输入测点位置']"
                v-model:value="searchModel.keyWord"
            />
          </a-form-item>

          <a-form-item>
            <div class="flex items-center">
              <div @click="reset" class="box px-5 py-1 fff cursor-pointer ml-4">
                {{ langObj['重置'] }}
              </div>

              <div
                  @click="getSensorList"
                  class="box px-5 py-1 fff cursor-pointer ml-4"
                  style="background-color: #072499"
              >
                {{ langObj['查询'] }}
              </div>

              <div
                  v-if="isEditable"
                  @click="addSensor"
                  class="box px-5 py-1 fff cursor-pointer ml-4"
                  style="background-color: #072499"
              >
                {{ langObj['新建测点'] }}
              </div>
            </div>
          </a-form-item>
        </a-form>

        <div class="box mt-4" style="width: 100%">
          <a-table
              style="width: 100%"
              size="small"
              :row-class-name="getRowClassName"
              :columns="sensorColumns"
              :data-source="data"
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'groupId'">
                <span>{{ getGroupName(record.machine.groupId) }}</span>
              </template>
              <template v-if="column.dataIndex === 'machineName'">
                <span>{{ record.machine.machineName }}</span>
              </template>
              <template v-if="column.dataIndex === 'hardware'">
                <span>{{
                    (record?.hardware?.vol || record?.hardware?.vol == 0) ? record.hardware.vol + ' %' : ''
                  }}</span>
              </template>
              <template v-if="column.dataIndex === 'validity'">
                <span>{{ getValidity(record.validityEnd) }}</span>
              </template>
              <template v-if="column.dataIndex === 'inspectionSubs'">
                <span>{{ getInspectionSubs(record.inspectionSubs) }}</span>
              </template>
              <template v-if="column.dataIndex === 'act'">
                <a v-if="isEditable" @click="editSensor(record)" style="color: #00fff4 !important"
                >{{ langObj['编辑'] }}</a
                >
              </template>
              <template v-if="column.dataIndex === 'parameter'">
                <div class="flex items-center">
                  <div v-for="(item, index) in record.sensorGroup" :key="index">
                    <a-tooltip placement="topLeft">
                      <template #title>
                        <div style="background: #fff">
                          <div>{{ langObj['传感器编号'] }}: {{ item.sonId }}</div>
                          <div>{{ langObj['安装坐标'] }}: {{ item.coordinate }}</div>
                          <div>{{ langObj['轴名称'] }}: {{ langObj[item.sensorGroupName] }}</div>
                        </div>
                      </template>
                      <a-tag color="#2db7f5">{{ item.coordinate }}</a-tag>
                    </a-tooltip>
                  </div>
                </div>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <!-- 新建测点 -->
    <a-modal
        v-model:visible="visibleAddSensor"
        :closable="false"
        :footer="null"
        :bodyStyle="{ background: '#020F46' }"
        style="top: 30%"
        width="1300px"
        :maskClosable="false"
    >
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleAddSensor = false">
            <close-outlined style="font-size: 20px"/>
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">
          {{ sensorForm.id ? langObj["编辑测点"] : langObj["新建测点"] }}
        </div>

        <div class="w-full mt-4">
          <a-form
              ref="sensorForm1"
              :model="sensorForm"
              name="horizontal_login"
              layout="inline"
              autocomplete="off"
              class="w-full"
              :label-col="{ style: { width: '150px' } }"
          >
            <a-row class="w-full" :gutter="24">
              <a-col :span="8">
                <a-form-item :label="langObj['传感器SN']" name="sensorSn" required>
                  <a-input
                      v-model:value="sensorForm.sensorSn"
                      :placeholder="langObj['请输入传感器SN']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['安装位置']" name="sensorPlace" required>
                  <a-input
                      v-model:value="sensorForm.sensorPlace"
                      :placeholder="langObj['请输入安装位置']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['所属设备']" name="machineName" required>
                  <div class="flex items-center">
                    <a-input
                        v-model:value="sensorForm.machineName"
                        :placeholder="langObj['请选择所属设备']"
                        readonly
                    ></a-input>
                    <div
                        @click="selectMachineS"
                        class="cursor-pointer"
                        style="
                        border: 1px solid #0d53b7cc;
                        color: #fff;
                        background-color: #072499;
                        width: 48px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                      "
                    >
                      {{ langObj['选择'] }}
                    </div>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="w-full" :gutter="24">
              <a-col :span="8">
                <a-form-item :label="langObj['转频提取']" name="fcSensorPlace">
                  <div class="flex items-center">
                    <a-input
                        v-model:value="sensorForm.fcSensorPlace"
                        :placeholder="langObj['请选择转频提取测点']"
                        readonly
                    ></a-input>
                    <div
                        @click="selectSensorS"
                        class="cursor-pointer"
                        style="
                        border: 1px solid #0d53b7cc;
                        color: #fff;
                        background-color: #072499;
                        width: 48px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                      "
                    >
                      {{ langObj['选择'] }}
                    </div>
                  </div>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['设备类型']" name="type">
                  <a-cascader
                      style="max-width:236px"
                      @change="changeThirdTypeId"
                      v-model:value="sensorForm.type"
                      :options="machineTypeList"
                      :placeholder="langObj['请选择设备类型']"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['功率']" name="national">
                  <a-select
                      style="width: 244px"
                      v-model:value="sensorForm.national"
                      :placeholder="langObj['请选择功率']"
                  >
                    <a-select-option
                        v-for="(item, index) in powerEtcList"
                        :key="index"
                        :value="item.powerEtc"
                    >{{ item.powerEtc }}
                    </a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>

              <!-- <a-col :span="8">
                <a-form-item label="主测点" name="onOff" required>
                  <a-switch v-model:checked="sensorForm.onOff" checked-children="开启" un-checked-children="关闭" />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item label="指标间隔" name="quotaInterval" required>
                  <a-input-number style="width: 244px" id="inputNumber" v-model:value="sensorForm.quotaInterval" />
                </a-form-item>
              </a-col> -->

              <a-col :span="8">
                <a-form-item :label="langObj['是否启用']" name="disable">
                  <a-switch v-model:checked="sensorForm.disable" :checked-children="langObj['禁用']"
                            :un-checked-children="langObj['启用']"/>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="getLanguage('智能诊断巡检配置', language)" name="inspectionSubId">
                  <!-- <a-switch v-model:checked="sensorForm.inspectionSubId" :checked-children="langObj['禁用']" :un-checked-children="langObj['启用']" /> -->
                  <a-cascader @change="changeISub" v-model:value="sensorForm.inspectionSubs" :options="options"
                              placeholder=""/>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="getLanguage('转速范围', language)" name="defaultSpeed">
                  <a-select
                      style="width: 244px"
                      v-model:value="sensorForm.defaultSpeed"
                      :placeholder="langObj['请选择']"
                  >
                    <a-select-option
                        v-for="(item, index) in speedList"
                        :key="index"
                        :value="item._id"
                    >{{ item.speedRange }}
                    </a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item :label="langObj['数据类型']">
                  <a-select
                      v-model:value="sensorForm.dataType"
                      mode="multiple"
                      :placeholder="langObj['请选择数据类型']"
                  >
                    <a-select-option v-for="(item, index) in dataTypeList" :key="index" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="w-full" :gutter="24">
              <a-col :span="24">
                <a-form-item :label="langObj['备注']" name="remark" class="w-full">
                  <a-textarea
                      v-model:value="sensorForm.remark"
                      show-count
                      :maxlength="200"
                      :placeholder="langObj['请输入备注']"
                      :rows="4"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="w-full" :gutter="24">
              <a-col :span="24">
                <a-form-item :label="langObj['三轴参数']" name="sn" class="w-full">
                  <div class="w-full" style="border: 1px solid #0d53b7cc">
                    <a-table
                        style="width: 100%"
                        size="small"
                        :pagination="false"
                        :locale="{ emptyText: ' ' }"
                        :columns="sensorAddColumns"
                        :data-source="sensorForm.sensorGroup"
                    >
                      <template #bodyCell="{ column, text, index, record }">
                        <template v-if="column.dataIndex === 'act'">
                          <a
                              @click="deleteSensorParmar(index)"
                              style="color: #00fff4 !important"
                          >{{ langObj['删除'] }}</a
                          >
                        </template>
                        <template v-if="column.dataIndex === 'sonId'">
                          <a-input
                              v-model:value="record.sonId"
                              :placeholder="langObj['请输入编号']"
                          ></a-input>
                        </template>
                        <template v-if="column.dataIndex === 'coordinate'">
                          <a-input
                              v-model:value="record.coordinate"
                              :placeholder="langObj['请输入安装坐标']"
                          ></a-input>
                        </template>
                        <template v-if="column.dataIndex === 'sensorGroupName'">
                          <a-input
                              v-model:value="record.sensorGroupName"
                              :placeholder="langObj['请输入轴名称']"
                          ></a-input>
                        </template>
                      </template>
                    </a-table>

                    <div
                        @click="addSensorParmar"
                        class="mx-4 my-2 cursor-pointer"
                        style="
                        border: 1px solid #0d53b7cc;
                        color: #fff;
                        background-color: #072499;
                        width: 48px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                      "
                    >
                      {{ langObj['添加'] }}
                    </div>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>

            <a-col :span="24">
              <a-form-item :label="langObj['测点照片']">
                <a-upload
                    v-model:file-list="machineFileList"
                    list-type="picture-card"
                    @preview="handlePreview"
                    :custom-request="customRequest"
                    :auto-upload="false"
                    accept="image"
                    :max-count="6"
                >
                  <div v-if="!machineFileList || machineFileList.length < 6">
                    <plus-outlined/>
                    <div style="margin-top: 8px; color: #fff">{{ langObj['上传'] }}</div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-form>

          <div class="w-full flex justify-center mt-4">
            <div
                @click="submitSensor"
                class="btn-default py-1 px-6 cursor-pointer"
            >
              {{ langObj['提交'] }}

            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <!-- 新建设备 -->
    <a-modal
        v-model:visible="visibleAddMachine"
        :closable="false"
        :footer="null"
        :bodyStyle="{ background: '#020F46' }"
        style="top: 30%"
        width="1300px"
        :maskClosable="false"
    >
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleAddMachine = false">
            <close-outlined style="font-size: 20px"/>
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">
          {{ machineForm.id ? langObj["编辑设备"] : langObj["新建设备"] }}
        </div>

        <div class="w-full mt-4">
          <a-form
              ref="macForm"
              :model="machineForm"
              layout="inline"
              autocomplete="off"
              class="w-full"
              :label-col="{ style: { width: '180px', wordWrap: 'break-word' } }"
          >
            <a-row class="w-full" :gutter="24">
              <a-col :span="12">
                <a-form-item :label="langObj['设备区域']" name="groupId" required>
                  <a-select
                      v-model:value="machineForm.groupId"
                      :placeholder="langObj['请选择设备区域']"
                      @change="selectGroupM"
                  >
                    <a-select-option
                        v-for="(item, index) in groupList"
                        :key="index"
                        :value="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备编号']" name="machineCode">
                  <a-input
                      v-model:value="machineForm.machineCode"
                      :placeholder="langObj['请输入设备编号']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备名称']" name="machineName" required>
                  <a-input
                      v-model:value="machineForm.machineName"
                      :placeholder="langObj['请输入设备名称']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备位置']" name="position">
                  <a-input
                      v-model:value="machineForm.position"
                      :placeholder="langObj['请输入设备位置']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备厂家']" name="supplier">
                  <a-input
                      v-model:value="machineForm.supplier"
                      :placeholder="langObj['请输入设备厂家']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备型号']" name="model">
                  <a-input
                      v-model:value="machineForm.model"
                      :placeholder="langObj['请输入设备型号']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备类型']" name="type">
                  <a-cascader
                      style="max-width:236px"
                      v-model:value="machineForm.type"
                      :options="machineTypeList"
                      :placeholder="langObj['请选择设备类型']"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备类型缩写']" name="machineType">
                  <a-input
                      v-model:value="machineForm.machineType"
                      :placeholder="langObj['请输入设备类型缩写']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备SN号']" name="sn">
                  <a-input
                      v-model:value="machineForm.sn"
                      :placeholder="langObj['请输入设备SN号']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['巡检序号']" name="sort" required>
                  <a-input-number
                      :controls="false"
                      style="width: 100%"
                      v-model:value="machineForm.sort"
                      placeholder="请输入"
                  />
                  <!-- <a-input v-model:value="machineForm.sort" placeholder="请输入设备序号" ></a-input> -->
                </a-form-item>
              </a-col>


              <a-col :span="12">
                <a-form-item :label="langObj['设备位号']" name="sn">
                  <!-- <a-input-number
                    :controls="false"
                    style="width: 100%"
                    v-model:value="machineForm.bitNumber"
                    placeholder="请输入设备位号"
                  /> -->
                  <a-input
                      v-model:value="machineForm.bitNumber"
                      :placeholder="langObj['请输入设备位号']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备功率']" name="sn">
                  <a-input
                      v-model:value="machineForm.machinePower"
                      :placeholder="langObj['请输入设备功率']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备ID']" name="sn">
                  <a-input
                      v-model:value="machineForm.params.ysshId"
                      :placeholder="langObj['请输入设备ID']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备执行人']" name="sn">
                  <a-input
                      v-model:value="machineForm.params.ysshExecutor"
                      :placeholder="langObj['请输入设备执行人']"
                  ></a-input>
                </a-form-item>
              </a-col>

              <a-col style="display: none;" :span="12">
                <a-form-item :label="langObj['智能诊断巡检配置']" name="threshold">
                  <a-cascader
                      @change="selectThreshold"
                      style="max-width:236px"
                      v-model:value="machineForm.thresholdIns"
                      :options="thresholdList"
                      :placeholder="langObj['请选择设备细分']"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['检测类别']" name="sn">
                  <a-checkbox-group v-model:value="machineForm.mode">
                    <a-checkbox :value="1">{{ langObj['在线监测'] }}</a-checkbox>
                    <a-checkbox :value="2">{{ langObj['巡检'] }}</a-checkbox>
                  </a-checkbox-group>
                </a-form-item>
              </a-col>

              <!-- <a-col :span="12">
                <a-form-item :label="langObj['是否实验台']" name="experiment">
                  <a-select
                    v-model:value="machineForm.experiment"
                    :placeholder="langObj['请选择是否为试验台']"
                  >
                    <a-select-option
                      v-for="(item, index) in whetherList"
                      :key="index"
                      :value="item.value"
                    >
                      {{ langObj[item.label] }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->

              <a-col :span="24">
                <a-form-item :label="langObj['设备描述']" name="remark">
                  <a-textarea
                      v-model:value="machineForm.remark"
                      placeholder="请输入"
                      :rows="4"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item :label="langObj['设备照片']">
                  <a-upload
                      v-model:file-list="machineFileList"
                      list-type="picture-card"
                      @preview="handlePreview"
                      :custom-request="customRequest"
                      :auto-upload="false"
                      accept="image"
                      :max-count="6"
                  >
                    <div v-if="!machineFileList || machineFileList.length < 6">
                      <plus-outlined/>
                      <div style="margin-top: 8px; color: #fff">{{ langObj['上传'] }}</div>
                    </div>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item class="w-full">
              <div class="w-full flex justify-center mt-5">
                <div
                    @click="submitMachine"
                    class="btn-default py-1 px-6 cursor-pointer"
                >
                  {{ langObj['提交'] }}
                </div>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-modal>

    <!-- 报警记录 -->
    <a-modal
        v-model:visible="visibleWarning"
        :closable="false"
        :footer="null"
        :bodyStyle="{ background: '#020F46' }"
        style="top: 30%"
        width="1400px"
        :maskClosable="false"
    >
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleWarning = false">
            <close-outlined style="font-size: 20px"/>
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">{{ langObj['报警记录'] }}</div>

        <div class="w-full mt-4">
          <div class="w-full">
            <a-form
                :model="searchModel"
                name="horizontal_login"
                layout="inline"
                autocomplete="off"
            >
              <!-- <a-form-item
                                label="设备区域"
                                name="groupId"
                            >
                                <a-select
                                    v-model:value="searchModel.groupId"
                                    style="width: 220px"
                                >
                                    <a-select-option value=""> {{ '全设备测点' }} </a-select-option>
                                </a-select>
                            </a-form-item>

                            <a-form-item
                                label="设备"
                                name="status"
                                >
                                <a-select
                                    v-model:value="searchModel.groupId"
                                    style="width: 220px"
                                >
                                    <a-select-option value=""> {{ '全设备测点' }} </a-select-option>
                                </a-select>
                            </a-form-item> -->

              <a-form-item :label="langObj['报警时间']" name="status">
                <a-range-picker v-model:value="warnSearchModel.date" :disabled-date="disabledDate"
                                @calendarChange="onCalendarChange"/>
              </a-form-item>

              <a-form-item :label="langObj['报警类型']" name="status">
                <a-select
                    v-model:value="warnSearchModel.type"
                    style="width: 220px"
                    allowClear
                    :placeholder="langObj['请选择报警类型']"
                >
                  <a-select-option
                      v-for="(item, index) in warnTypeList"
                      :key="index"
                      :value="item.value"
                  >
                    {{ langObj[item.label] }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="langObj['严重程度']" name="status">
                <a-select
                    v-model:value="warnSearchModel.condition"
                    style="width: 220px"
                    allowClear
                    :placeholder="langObj['请选择严重程度']"
                >
                  <a-select-option
                      v-for="(item, index) in conditionLists"
                      :key="index"
                      :value="item.value"
                  >
                    {{ langObj[item.label] }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item>
                <div class="flex items-center">
                  <div
                      @click="resetWarn"
                      class="box px-5 py-1 fff cursor-pointer ml-4"
                  >
                    {{ langObj['重置'] }}
                  </div>

                  <div
                      @click="(warnSearchModel.skip = 1), getWarningList()"
                      class="box px-5 py-1 fff cursor-pointer ml-4"
                      style="background-color: #072499"
                  >
                    {{ langObj['查询'] }}
                  </div>
                </div>
              </a-form-item>
            </a-form>
          </div>

          <div class="box mt-4" style="width: 100%">
            <a-table
                style="width: 100%"
                size="small"
                :row-class-name="getRowClassName"
                :columns="warningColumns"
                :data-source="warningList"
                :pagination="warmPagination"
                @change="changeWarnTable"
            >
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'type'">
                  <div class="flex items-center">
                    <div v-if="text === 1">
                      <img
                          style="width: 20px"
                          src="@/assets/zhenduan.png"
                          alt=""
                      />
                    </div>
                    <div v-if="text === 2">
                      <img
                          style="width: 20px"
                          src="@/assets/weixiu.png"
                          alt=""
                      />
                    </div>
                    <div v-if="text === 3">
                      <img
                          style="width: 20px"
                          src="@/assets/jiancha.png"
                          alt=""
                      />
                    </div>
                    <div v-if="text === 4">
                      <img
                          style="width: 20px"
                          src="@/assets/menxian.png"
                          alt=""
                      />
                    </div>
                    <div class="ml-2">
                      {{ langObj[warnTypeList.find((p) => p.value === text)?.label] }}
                    </div>
                  </div>
                </template>

                <template v-if="column.dataIndex === 'condition'">
                  <div class="flex items-center">
                    <div
                        style="width: 12px; height: 12px; border-radius: 50%"
                        :style="{
                        background: conditionList.find((p) => p.value === text)
                          ?.color,
                      }"
                    ></div>
                    <div class="ml-2">
                      {{ langObj[conditionList.find((p) => p.value === text)?.label] }}
                    </div>
                  </div>
                </template>

                <template v-if="column.dataIndex === 'status'">
                  <div></div>
                </template>

                <template v-if="column.dataIndex === 'act'">
                  <a
                      @click="viewReport(record)"
                      v-if="record.type === 1 && record.reportIds?.length"
                      style="color: #00fff4 !important"
                  >{{ langObj['诊断报告'] }}</a
                  >
                  <a
                      @click="viewReport(record)"
                      v-if="record.type === 2 && record.reportIds?.length"
                      style="color: #00fff4 !important"
                  >{{ langObj['检修报告'] }}</a
                  >
                </template>
              </template>
            </a-table>
          </div>
        </div>

        <div class="w-full flex justify-center mt-5">
          <div @click="visibleWarning = false" class="btn-default py-1 px-6">
            {{ langObj['关闭'] }}
          </div>
        </div>
      </div>
    </a-modal>

    <!-- 检修记录 -->
    <a-modal
        v-model:visible="visibleRepair"
        :closable="false"
        :footer="null"
        :bodyStyle="{ background: '#020F46' }"
        style="top: 30%"
        width="800px"
        :maskClosable="false"
    >
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleRepair = false">
            <close-outlined style="font-size: 20px"/>
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">{{ langObj['检修记录'] }}</div>

        <div class="mt-5" style="font-size: 16px">{{ langObj['故障信息'] }}</div>

        <div class="box mt-4" style="width: 100%">
          <a-table
              style="width: 100%"
              size="small"
              :row-class-name="getRowClassName"
              :columns="repairColumns"
              :data-source="repairForm.list"
          >
            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'act'">
                <a style="color: #00fff4 !important">{{ langObj['编辑'] }}</a>
              </template>
            </template>
          </a-table>
        </div>

        <div class="mt-10 mb-4" style="font-size: 16px">{{ langObj['故障详情'] }}</div>
        <a-descriptions
            :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }"
            :labelStyle="{ color: '#fff' }"
        >
          <a-descriptions-item
              :labelStyle="{ color: '#fff' }"
              label="开始时间"
          >{{ repairForm.begin }}
          </a-descriptions-item
          >
          <a-descriptions-item label="结束时间">{{
              repairForm.end
            }}
          </a-descriptions-item>
          <a-descriptions-item label="设备名称">{{
              repairForm.machineName
            }}
          </a-descriptions-item>
          <a-descriptions-item label="设备区域">{{
              repairForm.groupName
            }}
          </a-descriptions-item>
          <a-descriptions-item label="检修人员">{{
              repairForm.maintainPerson
            }}
          </a-descriptions-item>
          <a-descriptions-item label="现场设备状态">{{
              repairForm.accuracy
            }}
          </a-descriptions-item>
          <!-- <a-descriptions-item label="故障原因">xxxxx</a-descriptions-item> -->
        </a-descriptions>
        <div>{{ langObj['检修内容'] }}:</div>
        <div>{{ repairForm.maintainMessage }}</div>

        <div class="flex mt-4">
          <div>{{ langObj['检修照片'] }}:</div>
        </div>

        <div class="w-full flex justify-center mt-5">
          <div @click="visibleRepair = false" class="btn-default py-1 px-6">
            {{ langObj['关闭'] }}
          </div>
        </div>
      </div>
    </a-modal>

    <a-drawer
        v-model:visible="visibleReport"
        class="custom-class"
        size="large"
        :closable="false"
        placement="right"
    >
      <template #title></template>
      <DiagnosticReportDetails
          ref="mrDref"
          :reportId="reportId"
          @cancel="cancel"
      ></DiagnosticReportDetails>
    </a-drawer>

    <a-modal
        :visible="previewVisible"
        title="图片"
        :footer="null"
        @cancel="handleCancel"
        :zIndex="1001"
    >
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>

    <a-modal v-model:visible="visibleImportMachine"
             :title="langObj['导入设备'] || '导入设备'"
             :footer="null"
             style="top: 30%"
             width="1500px"
             :maskClosable="false">
      <import-machine :machine-types="machineTypeListAll" @success="onImportMachineSuccess"></import-machine>
    </a-modal>

    <SelectMachineComponent
        @selectMachineSen="selectMachineSen"
        :visible="selectMachineVisible"
        v-if="selectMachineVisible"
    ></SelectMachineComponent>
    <SelectSensorComponent
        @selectSensorSen="selectSensorSen"
        :machineId="sensorForm.machineId"
        :visible="selectSensorVisible"
        v-if="selectSensorVisible"
    ></SelectSensorComponent>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import {
  getFactoryName,
  getGroupName,
  transformDate2,
  getGroupListByFactory,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import SelectMachineComponent from "./select-machine.vue";
import SelectSensorComponent from "./select-sensor.vue";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
import { Dayjs } from 'dayjs';
import { getLanguage } from "../../common/translate";
import ImportMachine from "@/components/equipment/import-machine.vue";

let conditionLists = [
  { label: "健康", value: 1, realValue: [0] },
  { label: "可用", value: 2, realValue: [1, 2] },
  { label: "警戒", value: 3, realValue: [3, 4, 5] },
  { label: "故障", value: 4, realValue: [6, 7] },
];

let whetherList = [
  { label: "是", value: true },
  { label: "否", value: false },
];


const router = useRouter();
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]

  conditionLists = [
    { label: langObj.value["健康"], value: 1, realValue: [0] },
    { label: langObj.value["可用"], value: 2, realValue: [1, 2] },
    { label: langObj.value["警戒"], value: 3, realValue: [3, 4, 5] },
    { label: langObj.value["故障"], value: 4, realValue: [6, 7] },
  ];
}
getLang()

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let menuSelect = ref(1); //1: 设备列表  2：测点列表
let searchModel: any = ref({
  skip: 1,
  take: 10,
  keyWord: "",
  groupId: null,
  machineId: "",
  condition: null,
  status: null,
  sensorPlace: "",
});
let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];


let statusList = [
  { label: "运行", value: 1, color: "#0AF9F6" },
  { label: "停机", value: 0, color: "rgb(162, 166, 171)" },
];


let data = ref([]);

const machineColumns = [
  {
    title: langObj.value["设备编号"],
    dataIndex: "machineCode",
  },
  {
    title: langObj.value["设备名称"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["所属工厂"],
    dataIndex: "factoryId",
  },
  {
    title: langObj.value["设备区域"],
    dataIndex: "groupId",
  },
  {
    title: langObj.value["设备位置"],
    dataIndex: "position",
  },
  {
    title: langObj.value["设备厂家"],
    dataIndex: "supplier",
  },
  {
    title: langObj.value["设备型号"],
    dataIndex: "model",
  },
  {
    title: langObj.value["设备类型"],
    dataIndex: "machineTypeAll",
  },
  {
    title: langObj.value["设备类型缩写"],
    dataIndex: "machineType",
  },
  {
    title: langObj.value["设备SN号"],
    dataIndex: "sn",
  },
  {
    title: langObj.value["巡检序号"],
    dataIndex: "sort",
  },
  // {
  //   title: langObj.value["是否实验台"],
  //   dataIndex: "experiment",
  // },
  {
    title: langObj.value["设备描述"],
    dataIndex: "remark",
  },
  {
    title: getLanguage('监测类别', language.value),
    dataIndex: "mode",
  },
  {
    title: langObj.value["健康状态"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["运行状态"],
    dataIndex: "status",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

const sensorColumns = [
  {
    title: langObj.value["传感器SN"],
    dataIndex: "sensorSn",
  },
  {
    title: langObj.value["测点位置"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["设备区域"],
    dataIndex: "groupId",
  },
  {
    title: langObj.value["所属设备"],
    dataIndex: "machineName",
  },
  // {
  //   title: langObj.value["测点ID"],
  //   dataIndex: "id",
  // },
  {
    title: langObj.value["转频提取测点"],
    dataIndex: "fcSensorPlace",
  },
  {
    title: langObj.value["轴参数"],
    dataIndex: "parameter",
  },
  // {
  //   title: langObj.value["开机指标"],
  //   dataIndex: "threshold",
  // },
  {
    title: langObj.value["传感器电量"],
    dataIndex: "hardware",
  },
  {
    title: getLanguage('智能采集有效期', language.value),
    dataIndex: "validity",
  },
  {
    title: getLanguage('智能诊断巡检配置', language.value),
    dataIndex: "inspectionSubs",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

let groupList: any = ref([]);
let machineList: any = ref([]);
let machineTypeList: any = ref([]);
let thresholdList: any = ref([]);
let machineTypeListAll: any = ref([]);
let dataTypeList = ref<any>();
let isEditable: any = ref(false);

let options: any = ref([])
let categoryAll: any = ref([])

onMounted(() => {
  isEditable.value = true
  loadDatatype();
  getMachineType();
  getThresholdList()
  getGroupList();
  search();
  getSearch3()
});

const getSearch3 = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/external/inspection/threshold', config)
  if (result?.data) {
    categoryAll.value = JSON.parse(JSON.stringify(result.data))
  }

  options.value = []
  let first = categoryAll.value.map((p: any) => p.firstCategory)
  first = [...new Set(first)]
  let list: any = []
  first.map((f: any) => {
    let firstList: any = {
      label: f,
      value: f,
      children: []
    }
    let second = categoryAll.value.filter((p: any) => p.firstCategory === f).map((p: any) => p.secondCategory)
    second = [...new Set(second)]
    second.map((s: any) => {
      let secondList: any = {
        label: s,
        value: s,
        children: []
      }
      let third = categoryAll.value.filter((p: any) => p.firstCategory === f && p.secondCategory === s).map((p: any) => p.thirdCategory)
      third = [...new Set(third)]
      third.map((t: any) => {
        let thirdList: any = {
          label: t,
          value: t,
        }
        secondList.children.push(thirdList)
      })
      firstList.children.push(secondList)
    })

    list.push(firstList)
  })

  options.value = list
}


const loadDatatype = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/sensors/datatype", config).then((res: any) => {
    if (res) {
      dataTypeList.value = res.data.map((t: any) => ({
        value: t.name,
        label: langObj.value[t.name] || t.name,
      }));
    }
  });
};

// 获取设备类型
const getMachineType = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.FirstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
            result.data
                .filter((p: any) => p.FirstCategory === d1)
                .map((p: any) => p.SecondCategory)
        ),
      ];

      // console.log('list1', list1)
      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
            .filter((p: any) => p.SecondCategory === d2 && p.FirstCategory === d1)
            .map((p: any) => p.ThirdCategory);
        // console.log('list2', list2)

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    machineTypeList.value = list;
  }
};

// 获取设备细分
const getThresholdList = async () => {
  let result = await request("/api/external/inspection/threshold");
  if (result && result.data) {
    // machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.firstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
            result.data
                .filter((p: any) => p.firstCategory === d1)
                .map((p: any) => p.secondCategory)
        ),
      ];

      // console.log('list1', list1)
      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
            .filter((p: any) => p.secondCategory === d2 && p.firstCategory === d1)
            .map((p: any) => p.thirdCategory);
        // console.log('list2', list2)

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    console.log(list)
    thresholdList.value = list;

  }
};
//选择设备细分
const selectThreshold = (ev) => {
  macForm.value.thresholdArr = JSON.parse(JSON.stringify(ev))

}// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  searchModel.value.machineId = "";
  if (ev) {
    machineList.value = memberInfo.machines.filter(
        (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

const search = () => {
  searchModel.value.skip = 1;
  getData();
};

const getData = () => {
  if (menuSelect.value === 1) {
    getMachineList();
  }

  if (menuSelect.value === 2) {
    searchSensor();
  }
};

//获取设备列表
const getMachineList = async () => {
  let config: any = {
    params: {
      groupId: searchModel.value.groupId,
      status: searchModel.value.status,
      factoryId: localStorage.getItem("factory_id"),
      keyWord: searchModel.value.keyWord,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (searchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
        (p: any) => p.value === searchModel.value.condition
    )?.realValue;
  }

  let result = await request("/api/machines", config);

  if (result && result.status === 200) {
    result.data.map((d: any) => {
      if (d.machineTypeId) {
        let info = machineTypeListAll.value.find(
            (p: any) => p.id === d.machineTypeId
        );
        if (info) {
          d.machineTypeAll = `${info.FirstCategory}/${info.SecondCategory}/${info.ThirdCategory}`;
        } else {
          d.machineTypeAll = ''
        }
      }
    });
    data.value = result.data;
  }
};

// 获取测点列表
const searchSensor = () => {
  searchModel.value.groupId = groupList.value[0].id;
  selectGroup(searchModel.value.groupId);
  searchModel.value.machineId = machineList.value[0].id;
  getSensorList();
};

const getSensorList = async () => {
  if (!searchModel.value.machineId) {
    return message.warning('请选择设备后查询')
  }

  data.value = [];
  let config: any = {
    params: {
      machineId: searchModel.value.machineId,
      keyWord: searchModel.value.keyWord,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors", config);

  if (result && result.status === 200) {
    data.value = result.data;
  }
};

// 重置
const reset = () => {
  if (menuSelect.value === 1) {
    searchModel.value = {
      ...searchModel.value,
      keyWord: "",
      groupId: null,
      machineId: "",
      condition: null,
      status: null,
      sensorPlace: "",
    };
  } else {
    searchModel.value = {
      ...searchModel.value,
      keyWord: "",
      condition: null,
      status: null,
      sensorPlace: "",
    };
  }
};

const selectMenu = (ev: any) => {
  menuSelect.value = ev;
  data.value = [];
  reset();
  getData();
};

// 获取设备健康状态
const getMachienCondition = (ev: any) => {
  if (ev && ev.workStatus && ev.workStatus.stableCondition) {
    return conditionList.find(
        (p: any) => p.value === ev.workStatus.stableCondition
    );
  } else {
    return conditionList[0];
  }
};

// 获取设备运行状态
const getMachienStatus = (ev: any) => {
  if (ev && ev.workStatus && ev.workStatus.scoreWkStatus) {
    return statusList.find((p: any) => p.value === ev.workStatus.scoreWkStatus);
  } else {
    return statusList[1];
  }
};

// 获取监测类别
const getMode = (ev: any) => {
  if (ev?.length) {
    let list: any = []
    ev.map((d: any) => {
      if (d == 1) {
        list.push(getLanguage('在线监测', language.value))
      }
      if (d == 2) {
        list.push(getLanguage('巡检', language.value))
      }
    })
    return list.join('/')
  } else {
    return ''
  }
}

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

// 新增测点
let visibleAddSensor = ref(false);
let sensorForm: any = ref({
  sensorSn: null,
  sensorPlace: "",
  machineName: "",
  machineId: "",
  fcSensorId: "",
  fcSensorPlace: "",
  machineTypeId: "",
  national: "",
  remark: "",
  type: null,
  sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
  disable: false,
  onOff: null,
  quotaInterval: null,
  inspectionSubId: null,
  inspectionSubs: null
});
let powerEtcList: any = ref([]);
let selectMachineVisible = ref(false);
let selectSensorVisible = ref(false);
const sensorAddColumns = [
  {
    title: langObj.value["编号"],
    dataIndex: "sonId",
  },
  {
    title: langObj.value["轴向"],
    dataIndex: "coordinate",
  },
  {
    title: langObj.value["名称"],
    dataIndex: "sensorGroupName",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "value",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
const addSensor = () => {
  sensorForm.value = {
    sensorSn: null,
    sensorPlace: "",
    machineName: "",
    machineId: "",
    fcSensorId: "",
    fcSensorPlace: "",
    machineTypeId: "",
    national: null,
    remark: "",
    dataType: ['高频加速度', '低频加速度Z', '速度Z'],
    type: null,
    sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
    disable: false,
    onOff: null,
    quotaInterval: null,
    inspectionSubId: null,
    inspectionSubs: null
  };
  machineFileList.value = [];
  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm1.value.clearValidate();
  }, 200);
};
let speedList: any = ref([])

// 选择设备
const selectMachineS = () => {
  selectMachineVisible.value = true;
};

const selectMachineSen = (ev: any) => {
  console.log("selectMachineSen", ev);
  selectMachineVisible.value = false;
  if (ev) {
    sensorForm.value.machineId = ev.id;
    sensorForm.value.machineName = ev.machineName;
  } else {
    sensorForm.value.machineId = "";
    sensorForm.value.machineName = "";
  }
};

//选择转频提取测点
const selectSensorS = () => {
  if (!sensorForm.value.machineId) {
    message.warning('请选择所属设备后选择转频提取测点')
    return
  }

  selectSensorVisible.value = true;
};

const selectSensorSen = (ev: any) => {
  console.log("selcetSensorSen", ev);
  selectSensorVisible.value = false;
  if (ev) {
    sensorForm.value.fcSensorId = ev.id;
    sensorForm.value.fcSensorPlace = ev.sensorPlace;
  } else {
    sensorForm.value.fcSensorId = "";
    sensorForm.value.fcSensorPlace = "";
  }
};

// 选择类型
const changeThirdTypeId = async (ev: any) => {
  if (ev && ev.length === 3) {
    let info = machineTypeListAll.value.find(
        (p: any) =>
            p.FirstCategory === ev[0] &&
            p.SecondCategory === ev[1] &&
            p.ThirdCategory === ev[2]
    );
    sensorForm.value.machineTypeId = info.id;

    let config = {
      params: { id: info.id },
      headers: {
        requestId: uuidv4(),
      },
    };

    let result = await request.get(
        "/api/sensors/machine-types/power-etc",
        config
    );
    if (!result?.data) {
      powerEtcList.value = [];
      sensorForm.value.national = null;
      return;
    }

    powerEtcList.value = result.data;
    if (result.data.length > 0) {
      sensorForm.value.national = result.data[0].powerEtc;
    } else {
      sensorForm.value.national = null;
    }
  }
};

const addSensorParmar = () => {
  sensorForm.value.sensorGroup.push({ coordinate: "Y" });
};

const deleteSensorParmar = (ev: any) => {
  sensorForm.value.sensorGroup.splice(ev, 1);
};

// 选择巡检设备细分
const changeISub = (ev: any) => {
  if (ev?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === ev[0] && p.secondCategory === ev[1] && p.thirdCategory === ev[2])
  } else {
    speedList.value = []
  }
}

// 编辑
const editSensor = async (ev: any) => {
  machineFileList.value = [];
  let evValue = JSON.parse(JSON.stringify(ev));
  sensorForm.value = evValue;
  if (ev.machineTypeId) {
    let info = machineTypeListAll.value.find(
        (p: any) => p.id === ev.machineTypeId
    );
    if (info) {
      sensorForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      sensorForm.value.type = []
    }

  }
  if (ev.machine) {
    sensorForm.value.machineName = ev.machine.machineName;
  }

  if (sensorForm.value.pictures) {
    sensorForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = sensorForm.value.pictures;
  }

  if (sensorForm.value?.inspectionSubs?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === sensorForm.value.inspectionSubs[0] && p.secondCategory === sensorForm.value.inspectionSubs[1] && p.thirdCategory === sensorForm.value.inspectionSubs[2])
  } else {
    speedList.value = []
  }

  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm1.value.clearValidate();
  }, 200);
};

let sensorForm1 = ref();
const submitSensor = () => {
  sensorForm1.value?.clearValidate();
  sensorForm1.value
      .validate()
      .then(async (res: any) => {
        let form = JSON.parse(JSON.stringify(sensorForm.value));
        form.sensorGroup && form.sensorGroup.map((p: any) => {
          if (p.sonId) {
            p.sonId = p.sonId.trim()
          }
        })

        if (form.inspectionSubs?.length !== 3) {
          form.inspectionSubs = []
        }
        if (form.type && form.type.length === 3) {
          let info = machineTypeListAll.value.find(
              (p: any) =>
                  p.FirstCategory === form.type[0] &&
                  p.SecondCategory === form.type[1] &&
                  p.ThirdCategory === form.type[2]
          );
          form.machineTypeId = info.id;
        } else {
          form.machineTypeId = null;
        }
        delete form.type;

        if (machineFileList.value && machineFileList.value.length) {
          form.pictures = machineFileList.value.map((p: any) => ({
            url: p.url,
            type: 9,
          }));
        }

        let result: any = form.id
            ? await request.put("/api/sensors/info", form)
            : await request.post("/api/sensors", form);


        if (result && result.status) {
          message.success("操作成功");
          visibleAddSensor.value = false;
          getSensorList();
        } else {
          message.warning("操作失败");
        }
      })
      .catch((err: any) => {
        console.log(err); //失败后执行
      });
};

// 获取有效期
const getValidity = (ev: any) => {
  if (!ev) {
    return ''
  }

  let now = new Date().getTime()
  let end = new Date(ev).getTime()

  if (now >= end) {
    return '已过期'
  } else {
    let date = Math.round((end - now) / (1000 * 60 * 60 * 24))
    return date + '天'
  }
}

// 获取巡检设备细分
const getInspectionSubs = (ev: any) => {
  if (ev?.length !== 3) {
    return ''
  } else {
    return ev.join('/')
  }
}

// 新增设备
let visibleAddMachine = ref(false);
let machineForm: any = ref({
  machineCode: null,
  groupId: null,
  type: null,
  machineName: "",
  position: null,
  supplier: null,
  model: null,
  machineType: null,
  machineTypeId: null,
  thresholdIns: null,
  thresholdArr: [],
  sn: null,
  sort: null,
  experiment: false,
  remark: null,
  pictures: [],
  factoryId: "",
  parentFactoryId: "",
  params: {},
  bitNumber: null,
  machinePower: null,
  mode: [],
});
let macForm = ref();
const addMachine = () => {
  machineForm.value = {
    machineCode: null,
    groupId: null,
    type: null,
    machineName: "",
    position: null,
    supplier: null,
    model: null,
    machineType: null,
    machineTypeID: null,
    // thresholdIns:null,
    // thresholdArr:[],
    sn: null,
    sort: null,
    experiment: false,
    remark: null,
    pictures: [],
    factoryId: "",
    parentFactoryId: "",
    params: {},
    bitNumber: null,
    machinePower: null,
    mode: [],
  };
  machineFileList.value = [];
  visibleAddMachine.value = true;
  setTimeout(() => {
    macForm.value.clearValidate();
  }, 200);
};

const editMachine = async (ev: any) => {
  machineFileList.value = [];
  let dataValue = JSON.parse(JSON.stringify(ev));
  if (!dataValue.params) {
    dataValue.params = {}
  }
  if (!dataValue.mode) {
    dataValue.mode = []
  }
  delete dataValue.machineTypeAll;
  machineForm.value = dataValue;
  if (ev.machineTypeId) {
    let info = machineTypeListAll.value.find(
        (p: any) => p.id === ev.machineTypeId
    );
    if (info) {
      machineForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      machineForm.value.type = null
    }
  }

  if (machineForm.value.pictures) {
    machineForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = machineForm.value.pictures;
  }

  visibleAddMachine.value = true;
  setTimeout(() => {
    macForm.value.clearValidate();
  }, 200);
};

let visibleImportMachine = ref(false);
const onImportMachineSuccess = () => {
  visibleImportMachine.value = false;
  getData();
}; 

const selectGroupM = (ev: any) => {
  machineForm.value.factoryId = groupList.value.find(
      (p: any) => p.id === ev
  )?.factoryId;
  machineForm.value.parentFactoryId = groupList.value.find(
      (p: any) => p.id === ev
  )?.parentId;
};

//设备图片
let machineFileList = ref<any>([]);

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

//**图片上传* */
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};

const submitMachine = () => {
  macForm.value?.clearValidate();
  macForm.value
      .validate()
      .then(async (res: any) => {
        let form = JSON.parse(JSON.stringify(machineForm.value));
        if (machineFileList.value && machineFileList.value.length) {
          form.pictures = machineFileList.value.map((p: any) => ({
            url: p.url,
            type: 1,
          }));
        }

        if (form.type && form.type.length === 3) {
          let info = machineTypeListAll.value.find(
              (p: any) =>
                  p.FirstCategory === form.type[0] &&
                  p.SecondCategory === form.type[1] &&
                  p.ThirdCategory === form.type[2]
          );
          form.machineTypeId = info.id;
        } else {
          form.machineTypeId = null;
        }
        delete form.type;

        let result = form.id
            ? await request.put("/api/machines/info", form)
            : await request.post("/api/machines", form);
        if (result && result.status === 200) {
          message.success("操作成功");
          visibleAddMachine.value = false;
          getData();
        } else {
          message.warning("操作失败,请联系管理员!");
        }
      })
      .catch((err: any) => {
        console.log(err); //失败后执行
      });
};

// 报警记录
let visibleWarning = ref(false);
let warningList: any = ref([]);
let warnSearchModel: any = ref({
  machineId: null,
  date: null,
  type: null,
  condition: null,
  skip: 1,
  take: 20,
  total: 0,
  keyWord: "",
});
const warmPagination = computed(() => ({
  total: warnSearchModel.value.total,
  current: warnSearchModel.value.skip,
  pageSize: warnSearchModel.value.take,
}));
const changeWarnTable = (pagination: any) => {
  searchModel.value.skip = pagination.current;
  searchModel.value.take = pagination.pageSize;
  getWarningList();
};
let warnTypeList = [
  { label: '智能诊断', value: 1 },
  { label: '门限报警', value: 4 },
]
let warningColumns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "contactName",
  },
  {
    title: langObj.value["处理状态"],
    dataIndex: "status",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

const resetWarn = () => {
  warnSearchModel.value = {
    ...warnSearchModel.value,
    date: null,
    type: null,
    condition: null,
  };
};

const openMachineWarining = (ev: any) => {
  console.log("ev", ev);
  warnSearchModel.value.machineId = ev.id;
  visibleWarning.value = true;
  warnSearchModel.value.skip = 1;
  resetWarn();
  getWarningList();
};

const getWarningList = async () => {
  warningList.value = [];
  let config: any = {
    params: {
      skip: warnSearchModel.value.skip,
      take: warnSearchModel.value.take,
      machineId: warnSearchModel.value.machineId,
      typeList: [1, 4],
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (warnSearchModel.value.type) {
    delete config.params.typeList;
    config.params.type = warnSearchModel.value.type;
  }

  if (warnSearchModel.value.date && warnSearchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
        new Date(warnSearchModel.value.date[0]),
        1
    );
    config.params.end = transformDate2(
        new Date(warnSearchModel.value.date[1]),
        2
    );
  }

  if (warnSearchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
        (p: any) => p.value === warnSearchModel.value.condition
    )?.realValue;
  }

  let result = await request.get("/api/workflows", config);
  console.log("getWarningList", result);
  if (result) {
    warningList.value = result.data.list;
    warnSearchModel.value.total = result.data.total;
  }
};

//检修记录
let visibleRepair = ref(false);
let repairForm: any = ref({
  list: [],
});

let repairColumns = [
  {
    title: "报警时间",
    dataIndex: "id",
  },
  {
    title: "严重程度",
    dataIndex: "id",
  },
  {
    title: "故障描述",
    dataIndex: "id",
  },
];

let accuracyList = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 2 },
  { label: "故障", value: 3 },
  { label: "不确定", value: 4 },
];

const getRepairInfo = async (id: any) => {
  console.log("id", id);
  repairForm.value = { list: [] };

  let config: any = {
    params: {
      id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/reports/info", config);

  console.log("result", result);
  if (result && result.data) {
    repairForm.value = {
      begin: result.data.repairReport.begin,
      end: result.data.repairReport.end,
      maintainMessage: result.data.repairReport.maintainMessage,
      maintainPerson: result.data.repairReport.maintainPerson,
      machineName: result.data.machines[0].machineName,
      accuracy: accuracyList.find(
          (p: any) => p.value === result.data.repairReport.accuracy
      )?.label,
    };
    if (result.data.machines.length) {
      repairForm.value.groupName = getGroupName(
          result.data.machines[0].groupId
      );
    }
  }
};

// 健康总览
const viewMachine = (ev: any) => {
  console.log(ev);
  if (ev) {
    localStorage.setItem("machine_id", ev.id);
    router.push("/diagnosisN/overview");
  }
};

// 查看专家报告
let visibleReport = ref(false);
let reportId = ref("");
let mrDref = ref();
const viewReport = (ev: any) => {
  visibleReport.value = true;
  reportId.value = ev.reportIds[0];
  setTimeout(() => {
    console.log("mrDref.value", mrDref.value);
    mrDref.value.show(ev.reportIds[0]);
  }, 500);
};
const cancel = (ev: any) => {
  if (ev) {
    visibleReport.value = false;
    reportId.value = "";
  }
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


</script>

<style lang="less" scoped>
.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody > tr > td {
  border-bottom: 0px solid #fff;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}

/deep/ .ant-select-multiple .ant-select-selection-item {
  background: #07249933 !important;
}
</style>
