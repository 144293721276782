<template>
  <div
    class="w-full flex px-7 py-2"
    style="background-color: #000a31; color: #fff;overflow-y: scroll; height: calc(100vh - 100px);"
  >
    <!-- 左侧搜索 -->
    <div style="width: 250px">
      <machineListLeftComponent @selectMachine="selectMachine" @selectGroup="selectGroup" :type="3">
      </machineListLeftComponent>
    </div>

    <!-- 右侧内容 -->
    <div class="flex-1 ml-7" v-if="selectType == 1">
      <div class="w-full flex items-center">
        <span style="font-size: 24px">{{ machineInfo.machineName }}</span>
      </div>

      <div class="w-full flex mt-7">
        <!-- 左 -->
        <div style="min-width: 476px">
          <div class="w-full flex items-center">
            <div class="box2">
              <img class="w-full h-full" src="@/assets/cliqueD4.png" alt="" />
              <div class="content">
                <div
                  class="w-full"
                  :style="{ color: machineInfo?.condition?.color }"
                  style="font-size: 24px; text-align: center"
                >
                  {{ langObj[machineInfo?.condition?.label] }}
                </div>
                <div style="text-align: center">
                  {{ langObj['综合健康状态'] }}
                </div>
              </div>
            </div>

            <div class="box2 ml-5">
              <img class="w-full h-full" src="@/assets/cliqueD4.png" alt="" />
              <div class="content">
                <div
                  class="w-full"
                  :style="{ color: machineInfo?.status?.color }"
                  style="font-size: 24px; text-align: center"
                >
                  {{ langObj[machineInfo?.status?.label] }}
                </div>
                <div style="text-align: center">   {{ langObj['当前运行状态'] }}</div>
              </div>
            </div>
          </div>

          <div class="w-full mt-7 box3">
            <div class="w-full flex items-center">
              <img
                style="width: 18px; height: 18px"
                src="@/assets/machineD1.png"
                alt=""
              />
              <span class="size18 ml-2"> {{ langObj['设备信息'] }}</span>
            </div>

            <!-- <div class="mt-4 flex items-center">
              <div class="cursor-pointer">
                <left-outlined style="font-size: 35px; opacity: 0.5" />
              </div>

              <div class="flex-1">
                <img class="w-full" style="height: 150px" src="" alt="" />
              </div>

              <div class="cursor-pointer">
                <right-outlined style="font-size: 35px; opacity: 0.5" />
              </div>
            </div> -->
            <pictureListComponent
              :type="3"
              :picList="picListAll"
            ></pictureListComponent>

            <div class="w-full flex flex-wrap mt-4">
              <div class="w-1/2">
                <div style="opacity: 0.8">{{ langObj['设备名称'] }}</div>
                <div>{{ machineInfo.machineName }}</div>
              </div>
              <div class="w-1/2">
                <div style="opacity: 0.8">{{ langObj['设备区域'] }}</div>
                <div>{{ getGroupName(machineInfo.groupId) }}</div>
              </div>
              <div class="w-1/2 mt-2">
                <div style="opacity: 0.8">{{ langObj['设备厂家'] }}</div>
                <div>{{ machineInfo.supplier }}</div>
              </div>
              <div class="w-1/2 mt-2">
                <div style="opacity: 0.8">{{ langObj['设备编号'] }}</div>
                <div>{{ machineInfo.machineCode }}</div>
              </div>
            </div>
          </div>

          <div class="w-full mt-7 box3">
            <div class="w-full flex items-center">
              <img
                style="width: 18px; height: 18px"
                src="@/assets/machineD2.png"
                alt=""
              />
              <span class="size18 ml-2">{{langObj['设备状态占比']}}</span>
              <div class="flex-1"></div>
              <a-select
                ref="select"
                v-model:value="satusTimeDate"
                style="min-width: 120px"
                @change="getMachineStatusTime"
              >
                <a-select-option :value="1">
                  <div class="w-full flex items-center">
                    <calendar-outlined />
                    <div class="ml-2">{{langObj['近一周']}}</div>
                  </div>
                </a-select-option>

                <a-select-option :value="2">
                  <div class="w-full flex items-center">
                    <calendar-outlined />
                    <div class="ml-2">{{langObj['近一月']}}</div>
                  </div>
                </a-select-option>
              </a-select>
            </div>

            <div class="w-full flex mt-4">
              <div class="flex-1 flex flex-col" style="height: 290px">
                <div class="flex-1 mt-4">
                  <div
                    v-if="visibleChart"
                    id="chartAM"
                    style="height: 200px; width: 200px"
                  ></div>
                </div>

                <div class="w-full" style="text-align: center">
                  {{langObj['累计运行时间']}}：<span style="color: #4efaee">{{
                    conditionTotal.wkStatsu
                  }}</span>
                </div>
              </div>

              <div class="ml-4">
                <div>
                  <div class="flex items-center" style="font-size: 16px">
                    <div class="box4" style="background-color: #4efaee"></div>
                    <div class="ml-2"> {{langObj['运行占比']}} : </div>
                    <div class="ml-2">{{ conditionTotal.wkStatus1Rate }}%</div>
                  </div>
                  <div class="flex items-center" style="font-size: 13px">
                    <div style="width: 12px"></div>
                    <div class="ml-2 opt8">{{langObj['运行时间']}}:</div>
                    <div class="ml-2 opt8">
                      {{ conditionTotal.wkStatus1Date }}
                    </div>
                  </div>
                </div>

                <div class="mt-4">
                  <div class="flex items-center" style="font-size: 16px">
                    <div class="box4" style="background-color: #1cc48b"></div>
                    <div class="ml-2">{{ langObj['健康占比'] }}:</div>
                    <div class="ml-2">{{ conditionTotal.condition1Rate }}%</div>
                  </div>
                  <div class="flex items-center" style="font-size: 13px">
                    <div style="width: 12px"></div>
                    <div class="ml-2 opt8">{{langObj['健康时间']}}:</div>
                    <div class="ml-2 opt8">
                      {{ conditionTotal.condition1Date }}
                    </div>
                  </div>
                </div>

                <div class="mt-4">
                  <div class="flex items-center" style="font-size: 16px">
                    <div class="box4" style="background-color: #ddd246"></div>
                    <div class="ml-2">{{langObj['可用占比']}}:</div>
                    <div class="ml-2">{{ conditionTotal.condition2Rate }}%</div>
                  </div>
                  <div class="flex items-center" style="font-size: 13px">
                    <div style="width: 12px"></div>
                    <div class="ml-2 opt8">{{langObj['可用时间']}}:</div>
                    <div class="ml-2 opt8">
                      {{ conditionTotal.condition2Date }}
                    </div>
                  </div>
                </div>

                <div class="mt-4">
                  <div class="flex items-center" style="font-size: 16px">
                    <div class="box4" style="background-color: #cb6a34"></div>
                    <div class="ml-2">{{langObj['警戒占比']}}:</div>
                    <div class="ml-2">{{ conditionTotal.condition3Rate }}%</div>
                  </div>
                  <div class="flex items-center" style="font-size: 13px">
                    <div style="width: 12px"></div>
                    <div class="ml-2 opt8">{{langObj['警戒时间']}}:</div>
                    <div class="ml-2 opt8">
                      {{ conditionTotal.condition3Date }}
                    </div>
                  </div>
                </div>

                <div class="mt-4">
                  <div class="flex items-center" style="font-size: 16px">
                    <div class="box4" style="background-color: #b81212"></div>
                    <div class="ml-2">{{langObj['故障占比']}}:</div>
                    <div class="ml-2">{{ conditionTotal.condition4Rate }}%</div>
                  </div>
                  <div class="flex items-center" style="font-size: 13px">
                    <div style="width: 12px"></div>
                    <div class="ml-2 opt8">{{langObj['故障时间']}}:</div>
                    <div class="ml-2 opt8">
                      {{ conditionTotal.condition4Date }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 中 -->
        <div class="flex-1">
          <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="flex items-center mr-8">
              <div class="circle" style="background: #1cc48b"></div>
              <div class="ml-2">{{langObj['健康']}}</div>
              <div class="circle ml-8" style="background: #ddd246"></div>
              <div class="ml-2">{{langObj['可用']}}</div>
              <div class="circle ml-8" style="background: #cb6a34"></div>
              <div class="ml-2">{{langObj['警戒']}}</div>
              <div class="circle ml-8" style="background: #b81212"></div>
              <div class="ml-2">{{langObj['故障']}}</div>
            </div>
          </div>
          <div
            id="yulan"
            v-if="config_3d && config_3d.img"
            style="
              margin: auto;
              padding: 20px;
              width: 800px;
              height: 600px;
              position: relative;
            "
          >
            <img
              :src="config_3d.picture"
              style="position: absolute"
              :style="{
                width: config_3d.img.width + 'px',
                height: config_3d.img.height + 'px',
                top: config_3d.img.y + 'px',
                left: config_3d.img.x + 'px',
                transform: 'rotate(' + config_3d.img.rotate + 'deg)',
              }"
            />
            <!-- 加载标点 -->
            <div v-for="(item, index) in config_3d.marks" :key="index">
              <div
                class="d3_point"
                v-if="item.bind.indexOf('a-') > -1"
                :style="{ top: item.y + 'px', left: item.x + 'px' }"
              ></div>
            </div>
            <!-- 加载标签 -->
            <div v-for="(item, index) in config_3d.marks" :key="index">
              <div
                class="d3_bq"
                v-if="item.bind.indexOf('b-') > -1"
                :style="{
                  top: item.y - 50 + 'px',
                  left: item.x - 100 + 'px',
                  'border-color': getColor(item.condition),
                  'box-shadow': getShadow(item.condition),
                }"
                style="width: 225px; height: 100px"
              >
                <div class="w-full" style="padding: 5px 10px">
                  <div class="w-full flex">
                    <div>{{ item.sensorPlace }}</div>
                    <div class="flex-1"></div>
                    <div
                      :style="{ 'background-color': getColor2(item.condition) }"
                      style="width: 15px; height: 15px; border-radius: 50%"
                    ></div>
                  </div>
                  <div class="w-full flex items-center">
                    <div style="width: 35%">
                      <div>{{ item.hz }}m/s²</div>
                      <div style="font-size: 12px; opacity: 0.6">
                        {{langObj['振动加速度']}}
                      </div>
                    </div>
                    <div style="width: 35%">
                      <div>{{ item.sz }}mm/s</div>
                      <div style="font-size: 12px; opacity: 0.6">{{langObj['振动速度']}}</div>
                    </div>
                    <div style="width: 30%;word-wrap: break-word;">
                      <div>{{ item.temp }}℃</div>
                      <div style="font-size: 12px; opacity: 0.6">{{langObj['温度']}}</div>
                    </div>
                  </div>
                  <div class="w-full flex">
                    <div class="flex-1"></div>
                    <div class="mr-2" style="font-size: 12px; opacity: 0.6">
                      {{ item.date }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 划线 -->
            <div v-for="(item, index) in line" :key="index">
              <div
                class="d3_line"
                :style="{
                  top: item.y + 5 + 'px',
                  left: item.x + 5 + 'px',
                  width: item.distance + 'px',
                  transform: 'rotate(' + item.angle + 'deg)',
                }"
              ></div>
            </div>
          </div>
        </div>

        <!-- 右 -->
        <div>
          <div class="box3" style="width: 360px">
            <div class="w-full flex items-center">
              <img
                style="width: 18px; height: 18px"
                src="@/assets/machineD2.png"
                alt=""
              />
              <div class="size18 ml-2">{{langObj['设备诊断']}}</div>
            </div>

            <div class="w-full flex items-center justify-between mt-2">
              <div class="flex-1"></div>
              <div
                @click="selectTag(1)"
                class="btn1 cursor-pointer"
                :style="{ background: selecrRandar === 1 ? '#072499' : '' }"
              >
              {{langObj['振动指标']}} 
              </div>
              <div
                @click="selectTag(2)"
                class="btn1 cursor-pointer"
                :style="{ background: selecrRandar === 2 ? '#072499' : '' }"
                style="margin-left: 10px"
              >
              {{langObj['健康指标']}} 
              </div>
              <div
                @click="selectTag(3)"
                class="btn1 cursor-pointer"
                style="margin-left: 10px"
                :style="{ background: selecrRandar === 3 ? '#072499' : '' }"
              >
              {{langObj['分数趋势']}}
              </div>
              <div class="flex-1"></div>
            </div>

            <div class="w-full flex">
              <div class="flex-1"></div>
              <div
                v-if="visibleChart2"
                id="chartMachienB"
                style="width: 290px; height: 290px"
              ></div>
              <div class="flex-1"></div>
            </div>
          </div>

          <div class="box3 mt-7" style="width: 360px">
            <div class="w-full flex items-center">
              <img
                style="width: 18px; height: 18px"
                src="@/assets/machineD3.png"
                alt=""
              />
              <div class="size18 ml-2">{{langObj['设备事件']}}（{{ warningTotal }}条）</div>
              <div class="flex-1"></div>
              <div
                @click="viewMore"
                class="opt8"
                style="font-size: 12px; cursor: pointer"
              >
              {{langObj['查看更多']}}  >
              </div>
            </div>

            <div class="mt-2 w-full flex">
              <div class="flex-1"></div>
              <div
                @click="(warningType = 1), getMachineWarning()"
                :style="{ background: warningType === 1 ? '#072499' : '' }"
                class="btn1 cursor-pointer"
              >
              {{langObj['报警事件']}} 
              </div>
              <div
                @click="(warningType = 2), getMachineWarning()"
                :style="{ background: warningType === 2 ? '#072499' : '' }"
                class="btn99 cursor-pointer"
                style="margin-left: 10px"
              >
              {{langObj['检修记录']}}
              </div>
              <div class="flex-1"></div>
            </div>

            <div class="mt-2 w-full">
              <div
                v-for="(item, index) in machineWarningData"
                :key="index"
                class="box5 mt-2"
              >
                <div class="w-full flex items-center">
                  <img
                    v-if="item.type === 1"
                    style="width: 18px; height: 18px"
                    src="@/assets/zhenduan.png"
                    alt=""
                  />
                  <img
                    v-if="item.type === 2"
                    style="width: 18px; height: 18px"
                    src="@/assets/weixiu.png"
                    alt=""
                  />
                  <img
                    v-if="item.type === 4"
                    style="width: 18px; height: 18px"
                    src="@/assets/menxian.png"
                    alt=""
                  />
                  <div
                    v-if="item.type === 1"
                    :style="{
                      color: conditionList.find((p) =>
                        p.value.includes(item.condition)
                      )?.color,
                    }"
                    class="ml-2"
                    style="font-size: 16px"
                  >
                  {{langObj['诊断报醒']}}：{{
                    langObj[ conditionList.find((p) =>
                        p.value.includes(item.condition)
                      )?.label]
                    }}
                  </div>
                  <div class="ml-2" v-if="item.type === 2">{{langObj['现场维修']}}</div>
                  <div class="ml-2" v-if="item.type === 4">{{langObj['门限报警']}}</div>
                  <div class="flex-1"></div>
                  <div
                    v-if="
                      item.reportIds && item.reportIds.length && item.type === 1
                    "
                    class="box6 opt8"
                  >
                  {{langObj['诊断报告']}}
                  </div>
                  <div
                    v-if="
                      item.reportIds && item.reportIds.length && item.type === 2
                    "
                    class="box6 opt8"
                  >
                  {{langObj['检修报告']}}
                  </div>
                </div>

                <div class="mt-2">{{ item.diagnosticNotes }}</div>
                <div class="mt-2 opt6" style="font-size: 12px">
                  {{ item.date }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 ml-7" v-if="selectType == 2">
      <div class="w-full flex items-center">
        <span style="font-size: 24px">{{ group_info.name }}</span>
      </div>
      <div class="w-full flex">
        <!-- 左侧设备列表 -->
        <div >
          <div class="group-box mt-3 p-2" v-for="(item, index) in group_machineList" :key="index"> 
            <div class="w-full flex items-center" style="border-bottom: 1px solid rgba(62, 73, 114, 1);">
              <div class="shu"></div>
              <div class="ml-3">{{ item.name }}</div>
            </div>
            <div class="mt-2 w-full flex items-center">
              <div class="pic">
                <img style="width: 100%; max-height: 100%;" :src="item.picture"  alt="">
              </div>

              <div class="flex-1 ml-4">
                <div class="flex items-center" v-if="item.scoreWkStatus">
                  <div class="line">
                      <div class="line animation"></div>
                  </div>
                  <span class="text-gray-50 pl-2">{{langObj['运行']}}</span>
                </div>
                <div class="flex items-center" v-else>
                  <div class="down">
                      <div class="down animation"></div>
                  </div>
                  <span class="text-gray-50 pl-2">{{langObj['停机']}}</span>
                </div>


                <div class="flex items-center mt-2">
                  <div class="line" :style="{'background-color': item?.condition?.color}">
                      <div class="line animation" :style="{'background-color': item?.condition?.color}"></div>
                  </div>
                  <span class="text-gray-50 pl-2">{{ item?.condition?.label  }}</span>
                </div>
              </div>

            </div>
            
          </div>





        </div>
        <!-- 右侧区域3D大图 -->
        <div class="flex-1 flex">
          <div style="width: 10%;"></div>
            <div style="width: 80%;display: flex; justify-content: center; position: relative;">
                
                <div
                id="yulan"
                v-if="config_3d_group && config_3d_group.img"
                style="
                    margin: auto;
                    padding: 20px;
                    width: 1000px;
                    height: 800px;
                    position: relative;
                "
                >
                <img
                    :src="config_3d_group.picture"
                    style="position: absolute"
                    :style="{
                    width: config_3d_group.img.width + 'px',
                    height: config_3d_group.img.height + 'px',
                    top: config_3d_group.img.y + 'px',
                    left: config_3d_group.img.x + 'px',
                    transform: 'rotate(' + config_3d_group.img.rotate + 'deg)',
                    }"
                />
                <!-- 加载标点 -->
                <div v-for="(item, index) in config_3d_group.marks" :key="index">
                    <div
                    class="d3_point"
                    v-if="item.bind.indexOf('a-') > -1"
                    :style="{ top: item.y + 'px', left: item.x + 'px' }"
                    ></div>
                </div>
                <!-- 加载标签 -->
                <div v-for="(item, index) in config_3d_group.marks" :key="index">
                    <div
                    class="d3_bq"
                    v-if="item.bind.indexOf('b-') > -1"
                    :style="{
                        top: item.y - 50 + 'px',
                        left: item.x - 100 + 'px',
                        'border-color': getColor(0),
                    }"
                    style="width: 220px; height: auto"
                    >
                    <div class="w-full" style="padding: 5px 10px">
                        <div class="w-full flex">
                            <div style="font-size: 18px;">{{ group_info?.name }}</div>
                        </div>

                        <div 
                        v-for="(item, index) in group_machineList" :key="index" 
                        class="w-full flex items-center"
                        >
                            <div >{{ item. name}}</div>
                            <div class="flex-1"></div>
                            <div style="width: 16px;height: 16px;border-radius: 50%;min-width: 16px" :style="{'background': item.condition.color}"></div>
                        </div>
                        
                    </div>
                    </div>
                </div>
                <!-- 划线 -->
                <div v-for="(item, index) in lineGroup" :key="index">
                    <div
                    class="d3_line"
                    :style="{
                        top: item.y + 5 + 'px',
                        left: item.x + 5 + 'px',
                        width: item.distance + 'px',
                        transform: 'rotate(' + item.angle + 'deg)',
                    }"
                    ></div>
                </div>
                </div>









            </div>




        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "../components/machine-list-left.vue";
import pictureListComponent from "../components/picture-list.vue";
import { langList } from "../../common/lang";
import {
  transformDate,
  transformDate2,
  getGroupName,
  getFactoryName,
} from "../../common/tools";
import * as echarts from "echarts";
import { getLanguage } from "../../common/translate"
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

const router = useRouter();
let value = ref("");
let machine_id = "";
let machineInfo: any = ref({});

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f", color2: "#0D53B7" },
  { label: "可用", value: [1, 2], color: "#d2de49", color2: "#d2de49" },
  { label: "警戒", value: [3, 4, 5], color: "#cd5f3b", color2: "#cd5f3b" },
  { label: "故障", value: [6, 7], color: "#921e37", color2: "#921e37" },
];

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};

const getShadow = (ev: any) => {
  // box-shadow: inset 0 0 34px 0 #00249b;
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};

// let conditionLists = [
//     {label: '健康', value: 0, color: '#61c08f'},
//     {label: '可用', value: 1, color: '#d2de49'},
//     {label: '可用', value: 2, color: '#d2de49'},
//     {label: '警戒', value: 3, color: '#cd5f3b'},
//     {label: '警戒', value: 4, color: '#cd5f3b'},
//     {label: '警戒', value: 5, color: '#cd5f3b'},
//     {label: '故障', value: 6, color: '#921e37'},
//     {label: '故障', value: 7, color: '#921e37'},
// ]

let statusList = [
  { label: "停机", value: 0, color: "#ADADAD" },
  { label: "运行", value: 1, color: "#4EFAEE" },
];

let selectType:any = ref(1)

const selectMachine = (ev: any) => {
  selectType.value = 1
  machine_id = ev;
  selecrRandar.value = 2;
  picListAll.value = [];
  picList.value = [];
  init();
};

const init = () => {
  getMachineInfo();
  getMachineStatusTime();
  getMachineWarning();
};

// 获取设备详情
const getMachineInfo = async () => {
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/info", config);

  if (result) {
    machineInfo.value = result.data;
    if (
      machineInfo.value &&
      machineInfo.value.workStatus &&
      machineInfo.value.workStatus.stableCondition
    ) {
      machineInfo.value.condition = conditionList.find((p: any) =>
        p.value.includes(machineInfo.value.workStatus.stableCondition)
      );
    } else {
      machineInfo.value.condition = conditionList[0];
    }

    if (
      machineInfo.value &&
      machineInfo.value.workStatus &&
      machineInfo.value.workStatus.scoreWkStatus
    ) {
      machineInfo.value.status = statusList.find(
        (p: any) => p.value === machineInfo.value.workStatus.scoreWkStatus
      );
    } else {
      machineInfo.value.status = statusList[0];
    }
    load3D();
  }

  if (machineInfo.value.pictures?.length>0) {
    // picList.value.push({
    //   name: "设备",
    //   url: machineInfo.value.pictures[0].url,
    // });
    machineInfo.value.pictures.map((d:any) => {
      picList.value.push({
        name: "设备",
        url: d.url,
      });
    })
  }
  getSensorList();
  getRandarChartData();
};

let picList: any = ref([]);
let picListAll: any = ref([]);
const getSensorList = async () => {
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors", config);

  if (result && result.status === 200) {
    result.data.map((d: any) => {
      if (d.pictures) {
        d.pictures.map((p:any)=>{
          picList.value.push({
          name: d.sensorPlace,
          url: p.url
          })
        })
      }
    });
  }

  picListAll.value = picList.value;
};

// 获取设备状态占比
let satusTimeDate: any = ref(1);
let visibleChart = ref(true);
let conditionTotal: any = ref({
  condition1Date: "--",
  condition2Date: "--",
  condition3Date: "--",
  condition4Date: "--",
  condition1Rate: 0,
  condition2Rate: 0,
  condition3Rate: 0,
  condition4Rate: 0,
  wkStatus1Date: "--",
  wkStatus2Date: "--",
  wkStatus1Rate: 0,
  wkStatus2Rate: 0,
});
const getMachineStatusTime = async () => {
  conditionTotal.value = {
    condition1Date: "--",
    condition2Date: "--",
    condition3Date: "--",
    condition4Date: "--",
    condition1Rate: 0,
    condition2Rate: 0,
    condition3Rate: 0,
    condition4Rate: 0,
    wkStatus1Date: "--",
    wkStatus2Date: "--",
    wkStatus1Rate: 0,
    wkStatus2Rate: 0,
  };
  let time = new Date();
  let endDate = transformDate2(time, 1);


  // satusTimeDate.value = 3;
  // //本年
  // if (satusTimeDate.value === 3) {
  //   startDate = `${year}/01/01 00:00:00`;
  // }
  //本月
  let num = satusTimeDate.value === 2 ? 30 : 7
  
  let startTime = time.getTime()  - (1000 * 60 * 60 * 24 * num)
  let startDate = transformDate2(startTime, 1)


  let stableSearch = {
    params: {
      begin: startDate,
      end: endDate,
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result2 = await request.get(
    "/api/machines/workstatus/stables",
    stableSearch
  );
  let stableList: any = [];
  if (result2 && result2.data && result2.data.length) {
    stableList = result2.data;
  }

  let beginT = new Date(startDate).getTime();
  let endT = new Date(endDate).getTime();

  let condition0 = 0; //无
  let condition1 = 0; //健康
  let condition2 = 0; //可用
  let condition3 = 0; //警戒
  let condition4 = 0; //故障
  let conditionT = endT - beginT;
  if(stableList?.length > 0) {
    stableList.map((d: any, index: any) => {
      if (index == 0) {
        condition0 = new Date(d.date).getTime() - beginT;
      } else {
        let time =
          new Date(d.date).getTime() -
          new Date(stableList[index - 1].date).getTime();

        if (d.condition === 0) {
          condition1 = condition1 + time;
        }
        if (d.condition >= 1 && d.condition <= 2) {
          condition2 = condition2 + time;
        }
        if (d.condition >= 3 && d.condition <= 5) {
          condition3 = condition3 + time;
        }
        if (d.condition >= 6 && d.condition <= 7) {
          condition4 = condition4 + time;
        }
      }

      if (index === stableList.length - 1) {
        condition0 = condition0 + (endT - new Date(d.date).getTime());
      }
    });
  } else {
    condition1 = 0
    condition2 = 0
    condition3 = 0
    condition4 = 0
    condition0 = conditionT
  }
  

  let result4 = await request.get('/api/external/machines/onlines', stableSearch)
  let wkStatus1 = 0; //运行
  let wkStatus2 = 0; //停机
  let dateWK1 = 0
  if(result4 && result4.data) {
    result4.data.map((d:any) => {
      dateWK1 = dateWK1 + Math.round(d.online/3600)
    })
  }
  wkStatus1 = dateWK1 * 60 * 60 * 1000
  wkStatus2 = conditionT - wkStatus1

  function convertTimestamp(timestamp: any) {
    var milliseconds = timestamp;
    var date: any = new Date(milliseconds);

    var days = Math.floor(date / (24 * 60 * 60 * 1000));
    var hours = Math.floor((date % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    var minutes = Math.floor((date % (60 * 60 * 1000)) / (60 * 1000));
    return days + getLanguage("天", language.value) + ' ' + hours + getLanguage("时", language.value) + ' ' + minutes + getLanguage("分2", language.value);
  }

  conditionTotal.value = {
    condition1Date: convertTimestamp(condition1),
    condition2Date: convertTimestamp(condition2),
    condition3Date: convertTimestamp(condition3),
    condition4Date: convertTimestamp(condition4),
    condition0Rate: Number(((condition0 / conditionT) * 100).toFixed(0)),
    condition1Rate: Number(((condition1 / conditionT) * 100).toFixed(0)),
    condition2Rate: Number(((condition2 / conditionT) * 100).toFixed(0)),
    condition3Rate: Number(((condition3 / conditionT) * 100).toFixed(0)),
    condition4Rate: Number(((condition4 / conditionT) * 100).toFixed(0)),
    wkStatus1Rate: Number(((wkStatus1 / conditionT) * 100).toFixed(0)),
    wkStatus2Rate: Number(
      (((wkStatus2) / conditionT) * 100).toFixed(0)
    ),
    wkStatus1Date: convertTimestamp(wkStatus1),
    wkStatus2Date: convertTimestamp(wkStatus2),
    wkStatsu: convertTimestamp(wkStatus1)
  };

  visibleChart.value = false;
  visibleChart.value = true;

  setTimeout(() => {
    initMacineStatusRateChart();
  }, 500);
};

//绘制设备状态占比图
const initMacineStatusRateChart = () => {
  const ec = echarts as any;
  if (!document.getElementById("chartAM")) return;
  let myChart = ec.init(document.getElementById("chartAM"));

  const data = [
    {
      name: "外层饼图",
      children: [
        { value: conditionTotal.value.condition1Rate, name: "健康" },
        { value: conditionTotal.value.condition2Rate, name: "可用" },
        { value: conditionTotal.value.condition3Rate, name: "警戒" },
        { value: conditionTotal.value.condition4Rate, name: "故障" },
        { value: conditionTotal.value.condition0Rate, name: "停机" },
      ],
    },
    {
      name: "内层饼图",
      children: [
        { value: conditionTotal.value.wkStatus1Rate, name: "运行" },
        { value: conditionTotal.value.wkStatus2Rate, name: "停机" },
      ],
    },
  ];

  // 配置选项
  const option = {
    color: ["#1CC48B", "#DDD246", "#CB6A34", "#B81212", "#ADADB0"],
    grid: {
      right: "0%",
      left: "0%",
      top: 0,
      bottom: 0,
    },
    series: [
      {
        name: "外层饼图",
        type: "pie",
        radius: ["70%", "100%"], // 控制内外半径
        label: {
          show: false, // 不显示标签
        },
        data: data[0].children,
        emphasis: {
          scale: false, // 取消鼠标移入放大效果
        },
      },
      {
        name: "内层饼图",
        type: "pie",
        radius: ["40%", "70%"], // 控制内外半径
        label: {
          show: false, // 不显示标签
        },
        data: data[1].children,
        color: ["#4DFAED", "#ADADB0"],
        emphasis: {
          scale: false, // 取消鼠标移入放大效果
        },
      },
    ],
  };

  // 使用配置项显示图表
  myChart.setOption(option);
};

// 获取雷达图数据
let selecrRandar = ref(2); //1健康  2振动
let visibleChart2 = ref(true);
const getRandarChartData = async () => {
  let faultnamesSearch = {
    params: { machineId: machine_id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/faultnames", faultnamesSearch);
  let faultName = result.data.faultNames;
  let faultNameII = result.data.faultNameExpects;

  let Slist: any = [];
  let Tlist: any = [];

  if (
    machineInfo.value.workStatus &&
    machineInfo.value.workStatus.stableScores
  ) {
    for (let index in machineInfo.value.workStatus.stableScores) {
      const item = machineInfo.value.workStatus.stableScores[index];
      if (index.includes("S")) {
        Slist.push({
          quota: index,
          score: item,
          text:langObj.value[faultName.find((p: any) => p.column === index)?.title] || faultName.find((p: any) => p.column === index)?.title,
        });
      }

      if (index.includes("T")) {
        Tlist.push({
          quota: index,
          score: item,
          text: langObj.value[faultNameII.find((p: any) => p.column === index)?.title] || faultNameII.find((p: any) => p.column === index)?.title,
        });
      }
    }
  }

  machineInfo.value.Tlist = Tlist;
  machineInfo.value.Slist = Slist;

  // 获取分数趋势数据
  let end = transformDate(new Date());
  let begin = transformDate(new Date().getTime() - 1000 * 60 * 60 * 24);

  let search = {
    params: {
      begin,
      end,
      sensorId: "",
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let score = await request.get("/api/external/machines/score-full", search);
  let scoreAll = score.data;
 
  let scoreDataList: any = [];

  scoreAll.map((item: any) => {
    let d = item.date;
    let info1 = scoreAll.find((p: any) => p.date === d); 
    delete info1.date;
    delete info1.wkStatus;
    delete info1.condition;
    let list = Object.values(info1).sort(); 
    scoreDataList.push({
      date: d,
      value: list[0],
    });
  });

  machineInfo.value.scoreDataList = scoreDataList;

  visibleChart2.value = false;
  visibleChart2.value = true;

  setTimeout(() => {
    if (selecrRandar.value === 1) {
      initRandarChart(Slist);
    }
    if (selecrRandar.value === 2) {
      initRandarChart(Tlist);
    }
  }, 500);
};

// 绘制雷达图
const initRandarChart = (dataList: any) => {
  const ec = echarts as any;
  if (!document.getElementById("chartMachienB")) return;
  let myChart = ec.init(document.getElementById("chartMachienB"));
  myChart.clear();
  if (!dataList.length) return;
  let data: any = [];
  let error: any = [];
  let indicator: any = [];
  dataList.map((d: any) => {
    data.push(d.score);
    error.push(60);
    let color = "";
    if (d.score >= 0 && d.score < 60) {
      color = "#CD5F3B";
    }
    if (d.score >= 60 && d.score < 80) {
      color = "#D2DE49";
    }
    if (d.score >= 80 && d.score <= 100) {
      color = "#fff";
    }

    let name = ''
    if(language.value == "Chinese" && d.text) {
    if (d.text.length > 8) {
        name = (d.text.substring(0, 4) + '\n' + d.text.substring(4, 8) + '\n' + d.text.substring(8, d.text.length))
    } else if (d.text.length > 4 && d.text.length <= 8) {
        name = d.text.substring(0, 4) + '\n' + d.text.substring(4, d.text.length)
    } else {
        name = d.text
    }
    } 

    if(language.value == "English" && d.text) {
    let list1 = d.text.split(' ')

    let str:any = ''
    let num = 0
    let sy = ''
    list1.map((n:any, i:any) => {
        if(i==0) {
        if(n.length <= 10) {
            num = n.length
            str = n
        } else {
            num = 10
            let a1 = n.substring(0,10)
            sy = n.slice(10)
            str = a1
        }
        } else {

        if(sy) {
            str = str + '\n' ;
            let n2 = sy.length + n.length
            if(n2 <= 10) {
            num = n2
            str = str + sy + ' ' + n
            sy = ''
            } else {
            num = 10
            str = str + sy
            let n2 = 10 - sy.length >=0 ? 10 - sy.length : 0

            let a1 = n.substring(0,n2)
            sy = n.slice(10)
            str = str + a1
            }
        } else {
            if(num + n.length <= 10) {
            str = str + ' ' + n
            num = num + n.length
            } else {
            str = str + '\n'  + n
            num = n.length
            }
        }
        }
    })
    name = str
    }

    indicator.push({
      color,
      max: 100,
      text: name
    });
  });

  const option = {
    title: {
      text: "",
    },
    tooltip: {position: {left: '0px'}},
    radar: [
      {
        indicator: indicator,
        center: ["50%", "50%"],
        radius: 60,
        startAngle: 90,
        splitNumber: 5,
        name: {
          formatter: "{value}",
          textStyle: {
            color: "rgba(46, 214, 157, 1)",
            fontSize: 12,
          },
        },
        splitArea: {
          areaStyle: {
            color: ["#605969", "#453A47"],
          },
        },
        axisLine: {
          lineStyle: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },
        splitLine: {
          lineStyle: {
            color: ["rgb(241,243,250)"],
            width: 0,
          },
        },
      },
    ],
    series: [
      {
        name: "当前值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 4,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: data,
            lineStyle: {
              width: 1,
              color: "rgba(247,220,225,0.1)",
              normal: {
                type: "dotted",
              },
            },
            itemStyle: {
              color: "#FF4E13",
              borderWidth: 5,
            },
            areaStyle: {
              color: "#F7DCE1",
            },
          },
        ],
      },
      {
        name: "预警值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 1,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: error,
            name: "",
            lineStyle: {
              width: 2,
              color: "rgba(255, 25, 59, 0.2)",
            },
            itemStyle: {
              color: "rgba(255, 25, 59, 0.2)",
              borderWidth: 0,
            },
          },
        ],
      },
    ],
  };

  option && myChart.setOption(option);
};

//绘制分数趋势图
const initScoreChart = () => {
  let data = machineInfo.value.scoreDataList;
  const ec = echarts as any;
  if (!document.getElementById("chartMachienB")) return;
  let myChart = ec.init(document.getElementById("chartMachienB"));
  myChart.clear();
  let xData = data.map((p: any) => p.date);
  let yData = data.map((p: any) => p.value);

  let option = {
    color: ["#00FFF4"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [{ type: "inside" }],
    xAxis: {
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        showMaxLabel: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3,
        },
      },
      data: xData,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: yData,
        type: "line",
      },
    ],
  };

  myChart.setOption(option);
};

// 选择雷达图、分数趋势图
const selectTag = (ev: any) => {
  selecrRandar.value = ev;
  if (ev === 1) {
    initRandarChart(machineInfo.value.Slist);
  }

  if (ev === 2) {
    initRandarChart(machineInfo.value.Tlist);
  }

  if (ev === 3) {
    initScoreChart();
  }
};

// 获取设备事件
let warningType = ref(1);
let warningTotal = ref(0);
let machineWarningData: any = ref([]);
const getMachineWarning = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 3,
      machineId: machine_id,
      typeList: warningType.value === 1 ? [1, 4] : [2],
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/workflows", config);
  if (result && result.data) {
    machineWarningData.value = result.data.list;
    warningTotal.value = result.data.total;
  }
};

// 获取3D图测点数据
const get3DData = async () => {
  let config: any = {
    params: {
      id: machine_id,
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get(
    "/api/external/machines/workstatus/info",
    config
  );
  if (result && result.data) {
    config_3d.value.marks?.map((d: any) => {
      if (d.bind.includes("b-")) {
        let list = d.bind.split("-");
        if (list && list.length === 2) {
          let sensorId = list[1];
          let sensorInfo = result.data.sensors.find(
            (p: any) => p.id === sensorId
          );
          if (sensorInfo) {
            d.sensorPlace = sensorInfo.sensorPlace;
          } else {
            d.sensorPlace = "";
          }

          let info = result.data.quotas.find(
            (p: any) => p.sensorId === sensorId
          );
          if (info) {
            if (info.values.length) {
              let sz = info.values.find((p: any) => p.type === "速度Z");
              d.sz = sz ? sz.value.toFixed(2) : "0";
              let hz = info.values.find((p: any) => p.type === "高频加速度");
              d.hz = hz ? hz.value.toFixed(2) : "0";
              let temp = info.values.find((p: any) => p.type === "温度");
              d.temp = temp ? temp.value.toFixed(2) : "0";
            }
            d.date = info.date
          }
          let stableInfo = result.data.stableSensors && result.data.stableSensors.find(
            (p: any) => p.id === sensorId
          );
          if (stableInfo) {
            delete stableInfo.id;
            let list = Object.values(stableInfo).sort();
            let score: any = 100;
            if (list && list.length) {
              score = list[0];
            }

            if (score >= 80 && score <= 100) {
              d.condition = 0;
            }
            if (score >= 60 && score < 80) {
              d.condition = 1;
            }
            if (score >= 30 && score < 60) {
              d.condition = 3;
            }
            if (score >= 0 && score < 30) {
              d.condition = 6;
            }
          }
        }
      }
    });
  }
};

let markList = ref([]);

let config_3d = ref({
  marks: [],
});

let line = ref([]);

const load3D = async () => {
  config_3d.value = {};
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-3D", config);
  if (result) {
    if (
      (!result.data || !result.data.picture) &&
      machineInfo.value.machineTypeId
    ) {
      config = {
        params: {
          _id: machineInfo.value.machineTypeId,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      // 查询3d模型库
      let res = await request.get("/api/machine-type", config);

      if (res.data) {
        result.data = {
          picture:res.data.picture
        } ;
      }
    }

    config_3d.value = result.data || {
      marks: [],
    };
    markList.value = config_3d.value.marks;
  }
  line.value = [];
  if(config_3d.value.marks?.length >0){
    for (var i = 0; i < config_3d.value.marks.length; i++) {
    var item = config_3d.value.marks[i];
    if (item.bind.indexOf("a-") > -1) {
      var find = config_3d.value.marks.find(
        (t) =>
          t.bind.substring(2, t.bind.length) ==
            item.bind.substring(2, item.bind.length) &&
          t.bind.indexOf("b-") > -1
      );
      if (find) {
        var distance = Math.sqrt(
          Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
        );
        var angle_d =
          Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
        line.value.push({
          ...item,
          distance: distance,
          angle: angle_d,
        });
      }
    }
  }
  }

  get3DData();
};

// 查看更多
const viewMore = () => {
  router.push("/warning-manage/warning-list");
};


// 选择区域
let group_info:any = ref({})
let group_machineList:any = ref([])
let config_3d_group:any = ref({})
let lineGroup:any = ref([])

const selectGroup = (ev:any) => {
  selectType.value = 2
  if(ev) {
    group_info.value = ev
    group_machineList = ev.machineList || []
  }

  loadGroup3D()
}

const loadGroup3D = async() => {
    config_3d_group.value = { marks: [] };
    
    let config: any = {
        params: {
            groupId: group_info.value.id,
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/group-3D", config);
    console.log('result.data2222222222', result.data)
    if (result?.data) {
      config_3d_group.value = result.data

        markList.value = config_3d_group.value.marks;
    }

    
    lineGroup.value = [];
    for (var i = 0; i < config_3d_group.value.marks.length; i++) {
      var item = config_3d_group.value.marks[i];
      if (item.bind.indexOf("a-") > -1) {
          var find = config_3d_group.value.marks.find(
          (t:any) =>
              t.bind.substring(2, t.bind.length) ==
              item.bind.substring(2, item.bind.length) &&
              t.bind.indexOf("b-") > -1
          );
          if (find) {
          var distance = Math.sqrt(
              Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
          );
          var angle_d =
              Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
          lineGroup.value.push({
              ...item,
              distance: distance,
              angle: angle_d,
          });
          }
      }
        
    }
}

</script>

<style lang="less" scoped>
.group-box {
  width: 247px;
  height: 160px;
  background: rgba(7, 36, 153, 0.2);
  border: 1px solid #0D53B7;

  .shu {
    width: 2px;
    height: 10px;
    background: rgba(3, 243, 245, 1);
  }

  .pic {
    width: 120px;
    height: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.line {
  width: 10px;
  height: 10px;
  background-color: #4efaee;
  border-radius: 5px;
}

.down {
width: 10px;
height: 10px;
background-color: #29314d;
border-radius: 5px;
}

.animation {
  -webkit-animation: twinkling 5s infinite ease-in-out;
  animation: twinkling 5s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}



/deep/ .ant-input-affix-wrapper {
  background: #021768;
  border: 1px solid #0d53b7cc;
  color: #fff;
}

/deep/ .ant-input {
  background: #021768;
  color: #fff;
}

/deep/
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-select-arrow {
  color: #fff;
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

.box1 {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  width: 220px;
  padding: 20px 10px;
}

.box2 {
  width: 200px;
  height: 80px;
  position: relative;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 10px;
  }
}

.box3 {
  border: 1px solid #0d53b7;
  background: #02004d33;
  box-shadow: inset 0 0 34px 0 #00249b;
  border-radius: 2px;
  padding: 15px;
}

.box4 {
  width: 12px;
  height: 12px;
}

.box5 {
  width: 100%;
  border-radius: 2px;
  border: 1px solid #0d53b7cc;
  padding: 10px;
}

.box6 {
  width: 66px;
  // height: 24px;
  border-radius: 2px;
  border: 1px solid #ffffff66;
  text-align: center;
  // line-height: 24px;
}

.btn1 {
  width: 80px;
  // height: 30px;
  border-radius: 2px;
  opacity: 1;
  border: 1px solid #0d53b7;
  text-align: center;
  padding: 5px 5px;
  // line-height: 30px;
}
.btn99 {
  width: 90px;
  // height: 30px;
  border-radius: 2px;
  opacity: 1;
  border: 1px solid #0d53b7;
  text-align: center;
  box-sizing: border-box;
  padding: 5px 5px;
  // line-height: 30px;
}

.opt6 {
  opacity: 0.6;
}

.opt8 {
  opacity: 0.8;
}

.size18 {
  font-size: 18px;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
</style>
